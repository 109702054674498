/* eslint-disable */
export default () => {
  self.onmessage = (e) => {
    const { data, subentity, filterBy, formattedDEnd } = e.data;
    console.log('data', e.data);
    console.log('filter:', filterBy);
    const predicate = (() => {
      return (item) =>
        parseInt(formattedDEnd?.slice(0, 4)) >= parseInt(item.year) &&
        Object.keys(filterBy).every((key) => item[key] === filterBy[key]) &&
        (!item.discontinuedDate || item.discontinuedDate >= formattedDEnd);
    })();

    self.postMessage(data[subentity]?.filter(predicate));
  };
};

const hostname = window.location.hostname;

/**
 * Returns the application version based on the current hostname.
 * @param {string} version - The default version to be returned if no match is found. Default set to "v1"
 * @returns {string} - The determined application version.
 */
const getAppVersion = (defaultVersion = 'v1') => {
  const hostname = window.location.hostname;

  if (['staging2', 'testcyf', 'worldlandtrust.compareyourfootprint.com'].some((x) => hostname.includes(x))) return 'stage2';
  if (['staging', 'staging-green'].some((x) => hostname.includes(x))) return 'stage';
  if (['local', 'dev', 'testcyf'].some((x) => hostname.includes(x))) return 'dev';

  return defaultVersion;
};

const apiURL =
  hostname.includes('local') && process.env.REACT_APP_LOCAL_BACK_TEST
    ? 'https://localhost:3001'
    : hostname.includes('archerirm')
    ? `https://api.archerirm.compareyourfootprint.com/${getAppVersion()}`
    : `https://api.app.compareyourfootprint.com/${getAppVersion()}`;

module.exports = { apiURL, getAppVersion };

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Tooltip from '@mui/material/Tooltip';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { customStyles } from './codeStyles';
import { Box, LinearProgress } from '@mui/material';
import { ImportLinks, ImportUsageData, UploadFileBtn, TooltipText } from '../../styledComponents/GlobalStyle';
import ImportModal from './ImportModal';
import { CustomOption } from '../Common/ReusableComponents/Reusable';

const UploadUsageData = ({
  usageUploadType,
  usageUploadModal,
  uploadUsageType,
  processUpload,
  showUploadUsageDataModal,
  importUploadLink,
  getUploadLink,
  cyfAdmin,
  hasGoogleApiKey,
  branding,
  importModal,
  showLoader,
  progressState,
  alertObj,
  supportEmail,
}) => {
  const tooltipContent = () => {
    return (
      <>
        <p>
          To upload 'freight without distance' template, you would need to first set up a Google account to enable calls to
          DistanceMatric API. Then, an API 'key' will be provided to you to start importing freight. You will be billed
          directly by Google for setting up API keys.
        </p>
        <p>
          Should you need any assistance in setting up an account and obtain a 'key' to start importing freight without
          distance data, please do not hesitate to contact us at{' '}
          <a style={{ color: 'white' }} href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
          .
        </p>
        <p>
          Once you obtain the API key for your organisation, you could add it to your organisation details in the
          'Organisation' page.
        </p>
      </>
    );
  };

  const needsGoogleApiKey = usageUploadType?.value === 'freight_with_no_distances_use_api' ? true : false;
  const messageStyles = {
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 'bold',
  };

  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [importMessage, setImportMessage] = useState(false);
  const { severity, message } = alertObj;

  const selectFile = (e) => {
    const newFile = e.target.files && e.target.files[0];
    setFile(newFile);
    getUploadLink(newFile);
    e.target.value = '';
  };

  const handleFileRemove = () => {
    setInputDisabled(true); // disable the input
    setFile(null);
    setTimeout(() => {
      setInputDisabled(false); // re-enable the input
    }, 10); // wait for a brief delay
  };
  const usageImportOptions = [
    {
      label: 'Business Travel: Air',
      value: 'business_air_travel',
    },
    {
      label: 'Business Travel: Rail',
      value: 'business_rail_travel',
    },
    {
      label: 'Business Travel: Road',
      value: 'business_road_travel',
    },
    {
      label: 'Construction',
      value: 'construction',
    },
    {
      label: 'Electricity',
      value: 'electricity_with_fuel_mix',
    },
    /* {
      label: "Employee Commuting: Air",
      value: "employee_air_travel",
    }, */
    {
      label: 'Employee Commuting: Rail',
      value: 'commuting_rail_travel',
    },
    {
      label: 'Employee Commuting: Road',
      value: 'commuting_road_travel',
    },
    /*  {
       label: "Freight",
       value: "freight",
     }, */
    {
      label: 'Food and Drinks',
      value: 'food_drink',
    },
    {
      label: 'Freight With Distance',
      value: 'freight_distances_included',
    },
    {
      label: 'Freight Without Distance',
      value: 'freight_with_no_distances_use_api',
      googleApiKey: true,
    },
    {
      label: 'Gas',
      value: 'gas',
    },
    {
      label: 'Paper',
      value: 'paper',
    },
    /* {
      label: "Waste",
      value: "waste_green_element",
    } */
  ];

  if (cyfAdmin) {
    //show waste option just for GE team
    usageImportOptions.push({
      label: 'Waste',
      value: 'waste',
    });
  }

  useEffect(() => {
    if (!usageUploadModal) return;
    if (!showLoader) {
      setImportMessage(false);
    }
    if (severity === 'error') {
      setErrorMessage(message);
    }
    if (severity === 'success') setImportMessage(message);
    if (importModal) {
      setImportMessage(false);
      setErrorMessage(false);
    }
  }, [showLoader, message, severity, usageUploadModal, importModal]);

  const downloadFile = usageUploadType?.value || usageImportOptions[0].value;

  const [inputDisabled, setInputDisabled] = useState(false);

  const fileName = file?.name;

  if (!usageUploadModal) return null;
  const isInputDisabled = fileName || inputDisabled;

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              onClick={() => {
                setErrorMessage(false);
                setImportMessage(false);
                showUploadUsageDataModal(false);
                setFile(null);
              }}
              type='button'
              className='close'>
              x
            </button>
            <h4 className='modal-title'>Import usage data</h4>
          </div>
          <div className='modal-body'>
            {importMessage && <h5 style={messageStyles}>{importMessage}</h5>}

            {showLoader && !errorMessage && (
              <div className='progressBarArea'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <section className='panel'>
                      <aside className='profile-nav alt'>
                        <section className='panel'>
                          <Box sx={{ width: '100%', padding: '50px' }}>
                            <LinearProgress color='inherit' />
                          </Box>
                        </section>
                      </aside>
                    </section>
                  </div>
                </div>
              </div>
            )}

            {errorMessage && <h5 style={{ ...messageStyles, color: 'red' }}>{errorMessage}</h5>}

            {!progressState && !showLoader && !errorMessage && (
              <>
                <div className='form-group'>
                  <label>
                    Usage type <em>*</em>
                  </label>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Select
                      id='ImportUsageData-usageType'
                      value={usageUploadType || null}
                      components={{ Option: CustomOption('ImportUsageData') }}
                      onChange={(e) => {
                        uploadUsageType(e);
                        setFile(null);
                      }}
                      options={usageImportOptions}
                      styles={customStyles}
                      isClearable={false}
                      maxMenuHeight={5}
                    />

                    <div style={{ display: needsGoogleApiKey && !hasGoogleApiKey ? 'block' : 'none', marginLeft: 5 }}>
                      <Tooltip
                        title={<TooltipText>{tooltipContent()}</TooltipText>}
                        placement='right'
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: branding?.colors?.tooltipBackground,
                              color: branding?.colors?.tooltipText,
                            },
                          },
                          arrow: { sx: { color: branding?.colors?.tooltipBackground } },
                          popper: {
                            sx: {
                              display: needsGoogleApiKey && !hasGoogleApiKey ? 'block' : 'none',
                            },
                          },
                        }}>
                        <i
                          className='fa fa-question-circle'
                          style={{ fontSize: 18, cursor: 'pointer', color: branding.colors.primaryColor }}
                        />
                      </Tooltip>
                    </div>
                  </div>

                  <div id='import-link'>
                    <ImportUsageData>
                      <strong>Download template:</strong>
                    </ImportUsageData>

                    <ImportLinks
                      style={{ marginBottom: '2px' }}
                      id='import-link-excel'
                      href={`https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/${downloadFile}_template.xlsx`}
                      className='btn'
                      type='button'>
                      Excel
                    </ImportLinks>
                  </div>
                  <div id='file-name'>
                    <strong>File name: </strong>
                    <span>{file?.name}</span>
                  </div>
                  {needsGoogleApiKey && !hasGoogleApiKey && (
                    <strong style={{ fontSize: 16, color: 'red' }}>
                      Import requires an API key. Please hover over tooltip for guidance.
                    </strong>
                  )}
                </div>

                <div className='row block buttons' style={{ display: 'flex', gap: '5%', justifyContent: 'center' }}>
                  {fileName ? (
                    <UploadFileBtn
                      htmlFor='import_input'
                      className='custom-file-upload btn'
                      disabled={!usageUploadType}
                      onClick={handleFileRemove}>
                      <i className='fa fa-trash' />
                      &nbsp;
                      <span>Remove File</span>
                    </UploadFileBtn>
                  ) : (
                    <>
                      <UploadFileBtn
                        id='ImportUsageData-uploadFile'
                        htmlFor='import_input'
                        className='custom-file-upload btn'
                        disabled={!usageUploadType || (!hasGoogleApiKey && needsGoogleApiKey)}>
                        <i className='fa fa-cloud-upload' />
                        &nbsp;
                        <span>Upload File</span>
                      </UploadFileBtn>
                      <input
                        id='import_input'
                        type='file'
                        disabled={isInputDisabled}
                        accept='.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        onChange={selectFile}
                      />
                    </>
                  )}

                  <div>
                    <UploadFileBtn
                      id='ImportUsageData-startImport'
                      disabled={!file || !importUploadLink || (!hasGoogleApiKey && needsGoogleApiKey) || showLoader}
                      onClick={() => {
                        processUpload(file);
                        setFile(null);
                      }}
                      className='btn btn-success'>
                      {showLoader && <i className='fa fa-spin fa-spinner' style={{ marginRight: 5 }} />}
                      {showLoader ? 'Import Is Running' : 'Start Import'}
                    </UploadFileBtn>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {importModal && <ImportModal />}
    </div>
  );
};

const mapStateToProps = (state) => {
  const alertObj = state?.alertMessage || {};
  return {
    branding: state.branding,
    hasGoogleApiKey: state.organisation?.details?.googleApiKey ? true : false,
    usageUploadType: state.usageUploadType,
    importUploadLink: state.importUploadLink,
    importUploadKey: state.importUploadKey,
    usageUploadModal: state.usageUploadModal,
    cyfAdmin: state.profile?.details?.cyfAdmin,
    importModal: state.importModal,
    showingAlert: state?.alertMessage?.status,
    showLoader: state.showLoader,
    progressState: state?.progress >= 0 ? state.progress : null,
    alertObj,
    supportEmail: state?.whiteLabelAdmin?.supportEmail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  uploadUsageType: (usageData) => dispatch(USAGEACTIONS.uploadUsageType(usageData)),
  processUpload: (file) => dispatch(USAGEACTIONS.processUpload(file)),
  getUploadLink: (file) => dispatch(USAGEACTIONS.getUploadLink(file)),
  showUploadUsageDataModal: (status) => dispatch(USAGEACTIONS.showUploadUsageDataModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadUsageData);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { Actions as ORGANISATIONACTIONS } from '../../store/actions/organisationActions';
import { Box } from '@mui/material';
import { useState } from 'react';
import { Waveform } from '@uiball/loaders';

const ConfirmationModal = ({
  showConfirmModal,
  setShowConfirmModal,
  showUsageDataModal,
  progressState,
  edit,
  item,
  updateUsageYears,
  resetUsageDataModal,
}) => {
  const [progressBarActive, setProgressBarActive] = useState(false);

  useEffect(() => {
    if (progressState !== undefined) {
      setProgressBarActive(true);
    } else {
      setProgressBarActive(false);
      setShowConfirmModal(false);
    }

    if (progressState === 100) {
      updateUsageYears(item?.dateStart, item?.dateEnd);
      setShowConfirmModal(false);
      resetUsageDataModal(item);
    }
  }, [progressState, setShowConfirmModal]);

  if (showConfirmModal) {
    return (
      <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
        <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
          <div id='usage-confirmation-modal' className='modal-content'>
            <div className='modal-header'>
              <button
                onClick={() => {
                  showUsageDataModal(false, null);
                  setShowConfirmModal(false);
                }}
                type='button'
                className='close'>
                x
              </button>
              <h4 className='modal-title'>Usage Data</h4>
            </div>
            <div className='modal-body'>
              {/* <div className='form-group'>
                <h5 style={{ lineHeight: '25px', fontSize: '1.5em' }}>Your data has been successfully added!</h5>
                <h5 style={{ lineHeight: '25px', fontSize: '1.5em' }}></h5>
              </div> */}

              {/* {!progressBarActive && (
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div>
                    <AddButton
                      className='btn btn-success'
                      onClick={() => {
                        showUsageDataModal(true, null);
                        setShowConfirmModal(false);
                      }}>
                      Add more Data
                    </AddButton>
                  </div>
                  <div style={{ marginLeft: 30 }}>
                    <AddButton
                      className='btn btn-success'
                      onClick={() => {
                        showUsageDataModal(false, null);
                        setShowConfirmModal(false);
                      }}>
                      Exit
                    </AddButton>
                  </div>
                  <div className='clearfix'></div>
                </div>
              )} */}
              {progressBarActive && (
                <div
                  style={{
                    marginBottom: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}>
                  <h5 style={{ lineHeight: '25px', fontSize: '1.5em' }}>Saving your data...</h5>
                  <Box
                    sx={{
                      width: '100%',
                      height: '75%',
                      justifyContent: 'center',
                      display: 'flex',
                      alignItems: 'flex-start',
                      marginTop: '-3rem',
                      marginBottom: '2rem',
                    }}>
                    <Waveform size={40} lineWeight={3.5} speed={1} color='black' />
                    {/* {progress === 100 && (
                      <>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <p>Usage Data has been {`${edit ? 'edited' : 'added'}`} Successfully!</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <div>
                            <AddButton
                              id='UsageDataModal-addMoreData'
                              className='btn btn-success'
                              onClick={() => {
                                showUsageDataModal(true, null);
                                setShowConfirmModal(false);
                              }}>
                              Add more Data
                            </AddButton>
                          </div>
                          <div style={{ marginLeft: 30 }}>
                            <AddButton
                              id='UsageDataModal-exit'
                              className='btn btn-success'
                              onClick={() => {
                                showUsageDataModal(false, null);
                                setShowConfirmModal(false);
                              }}>
                              Exit
                            </AddButton>
                          </div>
                          <div className='clearfix'></div>
                        </div>
                      </>
                    )} */}
                  </Box>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state) => {
  return { progressState: state?.progress, edit: state?.usageDataModal?.item?.id, item: state?.usageDataModal?.item };
};

const mapDispatchToProps = (dispatch) => ({
  showUsageDataModal: (status, item) => dispatch(USAGEACTIONS.showUsageDataModal(status, item)),
  updateUsageYears: (startDate, endDate) => dispatch(ORGANISATIONACTIONS.updateUsageYears(startDate, endDate)),
  resetUsageDataModal: (item) => dispatch(USAGEACTIONS.resetUsageDataModal(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

const formatNumber = (value) => parseFloat(value || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 });

const EmissionsDisaggregatedByFacilityRow = ({ facility, facilityID, facilityData, organisations }) => {
  return (
    <tr>
      <td width='20%' style={{ textAlign: 'left' }}>
        {facility && facility.name}
      </td>
      <td width='20%' style={{ textAlign: 'center' }}>
        {organisations && organisations?.find((item) => item.id == facility?.organisationId)?.name}
      </td>
      <td>
        <span className='toggle-location'>
          {formatNumber(facilityData && facilityData.co2e ? facilityData.co2e.toFixed(3) : '0.000')}
        </span>
        <span className='toggle-market'>
          {formatNumber(facilityData && facilityData.marketScope ? _.sum(Object.values(facilityData.marketScope)) : '0.000')}
        </span>
      </td>
      <td>
        <span className='toggle-location'>
          {formatNumber(facilityData && facilityData.scope[1] ? facilityData.scope[1].toFixed(3) : '0.000')}
        </span>
        <span className='toggle-market'>
          {formatNumber(
            facilityData && facilityData.marketScope[1]
              ? facilityData.marketScope[1].toFixed(3)
              : facilityData && facilityData.scope[1]
              ? facilityData.scope[1].toFixed(3)
              : '0.000'
          )}
        </span>
      </td>
      <td>
        <span className='toggle-location'>
          {formatNumber(facilityData && facilityData.scope[2] ? facilityData.scope[2].toFixed(3) : '0.000')}
        </span>
        <span className='toggle-market'>
          {formatNumber(facilityData && facilityData.marketScope[2] ? facilityData.marketScope[2].toFixed(3) : '0.000')}
        </span>
      </td>
      <td>
        <span className='toggle-location'>
          {formatNumber(facilityData && facilityData.scope[3] ? facilityData.scope[3].toFixed(3) : '0.000')}
        </span>
        <span className='toggle-market'>
          {formatNumber(facilityData && facilityData.marketScope[3] ? facilityData.marketScope[3].toFixed(3) : '0.000')}
        </span>
      </td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { emissionsByFacility } = state.consumption;
  const adminOrgs = state.admin?.organisations;
  const orgnaisationList = state.organisation?.list;
  const { cyfAdmin, whiteLabelAdmin, greenElementUser } = state.profile?.details || {};
  const adminUser = cyfAdmin || whiteLabelAdmin || greenElementUser || false;

  const { facilityID } = ownProps;

  const facilityData =
    state.report &&
    state.report.details &&
    state.report.details.year &&
    emissionsByFacility[state.report.details.year] &&
    emissionsByFacility[state.report.details.year][facilityID];
  const facility =
    emissionsByFacility &&
    state?.facility?.hierarchyFacilities &&
    _.uniqBy(state.facility?.hierarchyFacilities, 'id')?.find((item) => item.id == facilityID);

  return {
    facilityData,
    facility,
    organisations: adminUser ? adminOrgs : orgnaisationList,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsDisaggregatedByFacilityRow);

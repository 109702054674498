import { Assessment } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import { addWeeks, format, lastDayOfWeek, parseISO } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import * as yup from 'yup';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { CalendarBtn, TooltipText } from '../../../styledComponents/GlobalStyle';
import Breadcrumb from '../../Common/Breadcrumb';
import { itemToOptions } from '../../utils/GlobalFunctions';
import { selectStyles } from '../../utils/ReactSelectStyles';
import ValidationMessage from '../../utils/ValidationMessage';
import ExistingContractModal from './ExistingContractModal';
import PricingBandModal from './PricingBandModal';

const schema = yup.object().shape({
  type: yup.string().required('Account type is required'),
  customerFirstName: yup.string().required('First name is required'),
  customerTitle: yup.string().required('Customer Title is required'),
  customerLastName: yup.string().required('Last name is required'),
  customerOrganisationName: yup.string().required('Organisation name is required'),
  customerBillingName: yup.string().required('Billing name is required'),
  customerEmail: yup.string().email('Invalid Email Address').required('Email is required'),
  customerRegisteredAddress: yup.string().required('Address is required'),
  customerCompanyNumber: yup.string().required('Company Number is required'),
  customerRegisteredCountry: yup.string().required('Country is required'),
  vatNumber: yup.string().when(['customerRegisteredCountry', 'type'], {
    is: (country, type) => {
      if (type === 'Ambassador') return false;
      if (country === '4297') return true;
    },
    then: yup.string().required('VAT Number is required when country is UK'),
    otherwise: yup.string().notRequired(),
  }),
  billingCurrency: yup.string().when('type', {
    is: (val) => val !== 'Ambassador',
    then: yup.string().required('Currency is required'),
  }),
  billingType: yup.string().when('type', {
    is: (val) => val !== 'Ambassador',
    then: yup.string().required('Billing Type is required'),
  }),
  perReportFee: yup.string().when('type', {
    is: (val) => val !== 'Ambassador',
    then: yup.string().required('Report Fee is required'),
  }),
  licenseFee: yup.string().when('type', {
    is: (val) => val !== 'Ambassador',
    then: yup.string().required('License Fee is required'),
  }),
  expectedStartDate: yup.string().when('type', {
    is: (val) => val === 'White Label',
    then: yup.string().required('Expected start date is required'),
  }),
  expectedDeliveryDate: yup.string().when('type', {
    is: (val) => val === 'White Label',
    then: yup.string().required('Expected delivery date is required'),
  }),
  wlDevelopmentFee: yup.string().when('type', {
    is: (val) => val === 'White Label',
    then: yup.string().required('Development Fee is required'),
  }),
  ambassadorCommissionRate: yup.string().when('type', {
    is: (val) => val === 'Ambassador',
    then: yup.string().required('Ambassador Commission Rate is required'),
  }),
});

const SalesNewContract = ({
  formOptions,
  accountTypeOptions,
  saveContract,
  adminOrgs,
  getAllItemsFromDB,
  fixedRates,
  contracts,
  pricingBands,
  branding,
}) => {
  const [accountType, setAccountType] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [showRangesModal, setShowRangesModal] = useState(false);
  const [existingClient, setExistingClient] = useState(false);
  const [showModal, setShowModal] = useState({ showModal: false, existingContract: null });
  const [editedState, setEditedState] = useState({});
  const [defaultFees, setDefaultFees] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState([]);
  const [disableReportFeeInput, setDisableReportFeeInput] = useState(false);
  const history = useHistory();
  const { primaryColor, tooltipBackground, tooltipText } = branding?.colors || {};

  useEffect(() => {
    (async () => {
      // Fetch default rates at first render and store it at local state
      const { data: { items } = {} } = await getAllItemsFromDB('fixedRates');
      setDefaultFees(items);
    })();
  }, [getAllItemsFromDB]);

  useEffect(() => {
    const fetchContracts = async () => await getAllItemsFromDB('contracts');
    fetchContracts();
  }, [getAllItemsFromDB]);

  useEffect(() => {
    // Reset the State
    if (accountType === 'Ambassador') setEditedState({});
    else setEditedState({ billingType: 'monthly' });

    // Reset Custom Input
    if (showCustomInput) setShowCustomInput(false);
    // Set Default values here
    if (accountType === 'White Label') {
      const newEditedState = { billingType: 'monthly' };
      const defaultValues = [
        { name: 'hasPaymentSystem', defaultValue: false },
        { name: 'billDevFeeOnce', defaultValue: false },
      ];
      defaultValues.forEach((x) => (newEditedState[x.name] = x.defaultValue));
      setEditedState(newEditedState);
    }
    // eslint-disable-next-line
  }, [accountType]);

  if (accountType !== 'White Label') {
    // If not white label, show tooltip message on expected start date field
    const toolTipMessage = 'This field is automatically populated when the customer signs the contract.';
    formOptions.expectedStartDate.tooltipText = toolTipMessage;
  } else {
    formOptions.expectedStartDate.tooltipText = '';
  }

  const updateField = (e) => {
    const perReportFee = ['customRangePrice', 'customPrice', '200'];
    const ambassadorFee = ['custom', '10', '20'];
    const wlDevelopmentFee = ['2000', '3000', 'custom'];
    const licenseFee = ['300', 'custom'];
    const { type, name, value, checked } = e.target || {};
    const newEditedState = { ...editedState };

    let defaultFee = null;
    if (defaultFees) {
      defaultFee = fixedRates.find(({ type, currency }) => type === accountType && currency === editedState.billingCurrency);
      if (defaultFee) {
        perReportFee.push(defaultFee?.['perReportFee']);
        wlDevelopmentFee.push(defaultFee?.['wlDevelopmentFee']);
        wlDevelopmentFee.push(defaultFee?.['wlDevelopmentFee2']);
        licenseFee.push(defaultFee?.['licenseFee']);
      }
    }

    // Handle the radio changes for ambassadorFee, wlDevelopmentFee and licenseFee values
    if (ambassadorFee.concat(wlDevelopmentFee).concat(licenseFee).includes(value) && type === 'radio') {
      // Show the input to enter the custom price
      if (value === 'custom') setShowCustomInput({ ...showCustomInput, [name]: true });
      // Show the input to enter the custom price
      else setShowCustomInput((prevState) => ({ ...prevState, [name]: false }));

      newEditedState[`${name}Val`] = value.includes('custom') ? '' : value;
    }

    // Handle the radio changes for perReportFee values
    if (perReportFee.includes(value) && type === 'radio' && name === 'perReportFee') {
      if (value !== 'customPrice') {
        // If the custom input is showing, close it as we don't need it
        if (showCustomInput) setShowCustomInput({ ...showCustomInput, [name]: false });
        // Show the modal to display the pricing bands
        if (value === 'customRangePrice') setShowRangesModal(true);
      } else {
        setShowCustomInput({ ...showCustomInput, [name]: true }); // Show the input to enter the custom price
      }

      // If fixed 200 is selected, set the value to 200 otherwise reset the value so user can type in the new value
      newEditedState[`${name}Val`] = ['200', defaultFee?.['perReportFee']].includes(value) ? value : '';
    }

    // billDevFeeOnce is a checkbox, so we need to set the value to true or false
    if (!value.includes('objectObject')) newEditedState[name] = name === 'billDevFeeOnce' ? checked : value;
    setEditedState(newEditedState);
  };

  const handleSave = async (contract) => {
    try {
      const contractFields = { ...contract, type: accountType };
      await schema.validate(contractFields, { abortEarly: false });
      setSaving(true);
      // Send fields and history as params, so if contracts are successfuly saved, we can redirect to the contract list page
      await saveContract(contractFields, history);
      setSaving(false);
    } catch (error) {
      setSaving(false);
      if (error.inner) {
        // If here, it means there are validation errors
        setError(error.inner);
      } else console.log('Error', error);
    }
  };

  return (
    <>
      <Breadcrumb items={[{ label: 'New Contract', link: '/admin/sales/newContract' }]} />
      <div className='row'>
        <div className='col-lg-12'>
          <section className='panel'>
            <aside className='profile-nav alt'>
              <section className='panel'>
                <header
                  className='panel-heading'
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  Account Type
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                      styles={{ ...selectStyles, container: (provided) => ({ ...provided, width: '15vw' }) }}
                      options={accountTypeOptions}
                      isClearable={true}
                      onChange={(selected) => {
                        if (error.length) setError([]);
                        if (!selected) {
                          // Reset the State
                          setAccountType('');
                          setEditedState({ billingType: 'monthly' });
                        } else setAccountType(selected.value);
                      }}
                    />
                    {accountType && (
                      <i
                        className={saving ? `fa fa-spin fa-spinner` : `far fa-save fa-lg`}
                        style={{ marginLeft: 20, fontSize: 30 }}
                        onClick={() => {
                          if (saving) return;
                          else handleSave(editedState);
                        }}
                      />
                    )}
                  </div>
                </header>
                {/* Show Contract Form if account Type or id is set  */}
                {accountType && (
                  <ul className='nav nav-pills nav-stacked'>
                    <li style={{ display: accountType !== 'Ambassador' ? 'block' : 'none', alignItems: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '10px 15px',
                        }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i className='fa fa-user' style={{ paddingRight: 20 }} /> Existing Client
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <RadioGroup
                            row
                            name='existingClient'
                            value={existingClient}
                            onChange={() => setExistingClient(!existingClient)}>
                            {['Yes', 'No'].map((opt, index) => {
                              return (
                                <div key={index}>
                                  <FormControlLabel
                                    style={{ marginTop: '0.75vh' }}
                                    control={<Radio />}
                                    value={opt === 'Yes' ? true : false}
                                    label={opt}
                                  />
                                </div>
                              );
                            })}
                          </RadioGroup>
                        </div>
                      </div>
                    </li>
                    {existingClient && (
                      <li>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px 15px',
                          }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i className='fa fa-building' style={{ paddingRight: 20 }} />
                            Select Organisation
                          </div>

                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Select
                              styles={{ ...selectStyles, container: (provided) => ({ ...provided, width: '25vw' }) }}
                              options={adminOrgs}
                              isClearable={true}
                              menuPlacement={'auto'}
                              onChange={(s) => {
                                if (!s) {
                                  setEditedState({});
                                  setShowModal({ showModal: false, existingContract: false });
                                } else {
                                  const contract = _.find(contracts, (x) => x.organisation === s.value && x.active === true);
                                  if (contract) {
                                    setShowModal({ showModal: true, existingContract: contract });
                                  } else {
                                    const newEditedState = { ...editedState };
                                    newEditedState.organisation = s.value;
                                    setShowModal((prev) => ({ ...prev, showModal: true }));
                                    setEditedState(newEditedState);
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </li>
                    )}
                    {Object.keys(formOptions)
                      .filter((o) => !formOptions[o]?.exclusiveFor || formOptions[o]?.exclusiveFor.includes(accountType))
                      .map((key, index) => {
                        const { expectedDeliveryDate, expectedStartDate, billingCurrency } = editedState || {};
                        const type = formOptions[key].type;
                        const styles = formOptions[key].style;
                        return (
                          <li key={index}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px 15px',
                              }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {typeof formOptions[key].icon == 'string' ? (
                                  // Fontawesome icons
                                  <i className={formOptions[key].icon} style={{ paddingRight: 20 }} />
                                ) : (
                                  // MUI icons
                                  formOptions[key].icon
                                )}
                                {formOptions[key].title}
                                {formOptions[key].tooltipText && (
                                  // In case options has some tooltip to show
                                  <Tooltip
                                    title={<TooltipText>{formOptions[key].tooltipText}</TooltipText>}
                                    arrow
                                    placement='right'
                                    componentsProps={{
                                      tooltip: { sx: { bgcolor: tooltipBackground, color: tooltipText } },
                                      arrow: { sx: { color: tooltipBackground } },
                                    }}>
                                    <i className='fas fa-info-circle' style={{ marginLeft: 10, color: primaryColor }} />
                                  </Tooltip>
                                )}
                              </div>
                              {['text', 'email', 'password'].includes(type) && (
                                <input
                                  data-lpignore='true'
                                  className='form-control'
                                  name={key}
                                  value={editedState?.[key] || ''}
                                  style={{ width: '25vw', height: 48, padding: 8 }}
                                  onChange={updateField}
                                  type={type}
                                />
                              )}
                              {type === 'select' && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Select
                                    styles={{ ...selectStyles, container: (provided) => ({ ...provided, ...styles }) }}
                                    options={formOptions[key]?.options}
                                    value={_.find(formOptions[key]?.options, { value: editedState?.[key] })}
                                    isClearable={true}
                                    menuPlacement={'auto'}
                                    isDisabled={key.includes('expectedDeliveryDate') && !expectedStartDate ? true : false}
                                    onChange={(selected) => {
                                      const newEditedState = { ...editedState };
                                      if (!selected) newEditedState[key] = '';
                                      else if (key.includes('expectedDeliveryDate') && expectedStartDate) {
                                        const startWeek = { weekStartsOn: 6 };
                                        const addWeeksToESD = addWeeks(parseISO(expectedStartDate), selected.value);
                                        // ESD should be expected start date + selected value and should be last day of the week
                                        newEditedState[key] = format(lastDayOfWeek(addWeeksToESD, startWeek), 'yyyy-MM-dd');
                                      } else {
                                        if (!selected.value && key === 'hasPaymentSystem') {
                                          // If hasPaymentSystem is not selected, then we should reset the stripeFee
                                          if (newEditedState['stripeFee']) newEditedState['stripeFee'] = '';
                                          if (disableReportFeeInput) setDisableReportFeeInput(false);
                                        } else if (selected.value && key === 'hasPaymentSystem') {
                                          // If hasPaymentSystem is selected, then we need to set perReportFeeVal to 0 and disable perReportFee toogle
                                          newEditedState['perReportFeeVal'] = 0;
                                          newEditedState['perReportFee'] = '0';
                                          setDisableReportFeeInput(true);
                                        }
                                        if (key === 'billingCurrency') {
                                          const { value } = selected;
                                          const defaultFees = fixedRates.find(
                                            ({ type, currency }) => type === accountType && currency === value
                                          );
                                          if (defaultFees) {
                                            // Default Fee that matches accountType and value found, update the state
                                            Object.keys(defaultFees).forEach((x) => {
                                              if (['perReportFee', 'licenseFee', 'wlDevelopmentFee'].includes(x)) {
                                                newEditedState[x] = defaultFees?.[x];
                                                newEditedState[`${x}Val`] = defaultFees?.[x];
                                              }
                                            });
                                          } else {
                                            // Default Fee that matches accountType and value not found, remove it from state
                                            ['perReportFee', 'licenseFee', 'wlDevelopmentFee'].forEach((y) => {
                                              if (editedState.hasOwnProperty(y)) {
                                                delete newEditedState[y];
                                                delete newEditedState[`${y}Val`];
                                              }
                                            });
                                          }
                                        }
                                        newEditedState[key] = selected?.value;
                                      }
                                      setEditedState(newEditedState);
                                    }}
                                  />
                                  {key.includes('expectedDeliveryDate') && expectedStartDate && expectedDeliveryDate && (
                                    <input
                                      className='form-control'
                                      name={key}
                                      value={format(parseISO(expectedDeliveryDate), 'dd/MM/yyyy') || ''}
                                      style={{ width: '10vw', height: 48, marginLeft: 8 }}
                                      disabled
                                    />
                                  )}
                                  {key.includes('hasPaymentSystem') && editedState[key] && (
                                    <input
                                      type='number'
                                      className='form-control'
                                      placeholder='CYF Commission %'
                                      name={'stripeFee'}
                                      onChange={updateField}
                                      value={editedState['stripeFee'] || ''}
                                      style={{ width: '10vw', height: 48, marginLeft: 8 }}
                                    />
                                  )}
                                </div>
                              )}
                              {type === 'toggle' && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <RadioGroup row name={key} value={editedState?.[key] || ''} onChange={updateField}>
                                    {formOptions[key]?.options?.map((opt, index) => {
                                      let label = opt?.title || opt;
                                      let value = opt?.value || opt;
                                      if (label === 'Custom Range & Price' && editedState?.['perReportFeeVal']) {
                                        // When selected 'Custom Range & Price' show the pricing band name
                                        const pricingBandName = _.find(pricingBands, {
                                          id: editedState?.['perReportFeeVal'],
                                        })?.name;
                                        label = pricingBandName || 'Custom Range & Price';
                                      }
                                      const defaultFees = _.find(
                                        fixedRates,
                                        (x) => x.currency === billingCurrency && x?.type === accountType
                                      );
                                      if (defaultFees?.[opt.defaultValue]) {
                                        label = `${billingCurrency} ${defaultFees?.[opt.defaultValue]}`;
                                        value = defaultFees?.[opt.defaultValue];
                                      }
                                      return (
                                        <div key={index}>
                                          <FormControlLabel
                                            style={{ marginTop: '0.75vh' }}
                                            control={
                                              <Radio
                                                disabled={key === 'perReportFee' && disableReportFeeInput ? true : false}
                                              />
                                            }
                                            value={_.camelCase(value)}
                                            label={label}
                                          />
                                        </div>
                                      );
                                    })}
                                  </RadioGroup>
                                  {showCustomInput && (showCustomInput[key] ? true : false) && (
                                    <input
                                      className='form-control'
                                      name={`${key}Val`}
                                      type={'number'}
                                      value={editedState?.[`${key}Val`] || ''}
                                      onChange={updateField}
                                      style={{ width: '10vw', height: 48, marginLeft: 8 }}
                                    />
                                  )}
                                </div>
                              )}
                              {type === 'date' && (
                                <label
                                  className='input-group date datepicker'
                                  style={{ fontWeight: 'normal', marginLeft: 10, width: '25vw' }}>
                                  <DatePicker
                                    dateFormat='dd/MM/yyyy'
                                    placeholderText={formOptions[key].title}
                                    className='form-control new-contract-start-date'
                                    selected={
                                      editedState?.[`${_.camelCase(formOptions[key].title)}`]
                                        ? parseISO(editedState?.[`${_.camelCase(formOptions[key].title)}`])
                                        : null
                                    }
                                    disabled={accountType !== 'White Label' ? true : false}
                                    isClearable={true}
                                    onChange={(date) => {
                                      if (!date) {
                                        setEditedState((prevState) => ({ ...prevState, [key]: '' }));
                                        if (expectedDeliveryDate && key.includes('expectedStartDate')) {
                                          // Reset the expected delivery date, when clearing ESD
                                          setEditedState((prevState) => ({ ...prevState, expectedDeliveryDate: '' }));
                                        }
                                      } else
                                        setEditedState((prevState) => ({
                                          ...prevState,
                                          [key]: format(date, 'yyyy-MM-dd'),
                                        }));
                                    }}
                                  />
                                  <span className='input-group-btn'>
                                    <CalendarBtn className='btn btn-primary date-set' style={{ height: 48 }}>
                                      <i className='fa fa-calendar' style={{ position: 'relative', top: 6 }} />
                                    </CalendarBtn>
                                  </span>
                                </label>
                              )}
                              {type === 'checkbox' && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    onChange={updateField}
                                    checked={editedState?.[key] || false}
                                    name={key}
                                  />
                                </div>
                              )}
                            </div>
                            {error.length > 0 && (
                              <ValidationMessage
                                message={_.find(error, { path: key })?.message}
                                style={{ fontSize: 12 }}
                                wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}
                              />
                            )}
                          </li>
                        );
                      })}
                  </ul>
                )}
              </section>
            </aside>
          </section>
        </div>
        {showRangesModal && (
          <PricingBandModal
            selectPriceBand={showRangesModal}
            showModal={showRangesModal}
            setShowModal={setShowRangesModal}
            editedState={editedState}
            setEditedState={setEditedState}
          />
        )}
        <ExistingContractModal
          setShowModal={setShowModal}
          showModal={showModal}
          setExistingClient={setExistingClient}
          setEditedState={setEditedState}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { countries } = state?.organisation || {};
  const { contractApprover, ambassadorCreator } = state.profile?.details || {};
  const { pricingBands, fixedRates } = state.admin?.adminPortal || {};
  const deliveryWeeks = [];
  for (let i = 5; i <= 12; i++) deliveryWeeks.push({ value: i, label: `${i} weeks` });

  const billingCurrencyOptions = [
    { id: '£', name: '£' },
    { id: '$', name: '$' },
    { id: '€', name: '€' },
    { id: 'AUD', name: 'AUD' },
  ].map(itemToOptions);

  const countryOptions = _.sortBy(
    countries?.map((item) => ({ label: item.name, code: item.code, value: item.id })),
    ['label']
  );

  const formOptions = {};
  formOptions.customerFirstName = { type: 'text', icon: 'fa fa-user', title: 'Customer First Name' };
  formOptions.customerLastName = { type: 'text', icon: 'fa fa-user', title: 'Customer Last Name' };
  formOptions.customerTitle = { type: 'text', icon: 'fa fa-user', title: 'Customer Title' };
  formOptions.customerOrganisationName = { type: 'text', title: 'Customer Organisation Name', icon: 'fa fa-cogs' };
  formOptions.customerBillingName = { type: 'text', title: 'Customer Company Billing Name', icon: 'fa fa-wallet' };
  formOptions.customerEmail = { type: 'email', title: 'Customer Email', icon: 'fa fa-at' };
  formOptions.customerRegisteredCountry = {
    type: 'select',
    title: 'Customer Registered Country',
    options: countryOptions,
    icon: 'fa fa-globe-europe',
    style: { width: '25vw' },
  };
  formOptions.customerRegisteredAddress = { type: 'text', title: 'Customer Registered Address', icon: 'fa fa-address-card' };
  formOptions.vatNumber = {
    type: 'text',
    title: 'VAT Number',
    icon: 'fa fa-university',
    exclusiveFor: ['White Label', 'Consultant'],
  };
  formOptions.customerCompanyNumber = { type: 'text', title: 'Customer Company Number', icon: 'fa fa-registered' };
  formOptions.billingCurrency = {
    type: 'select',
    title: 'Billing Currency',
    options: billingCurrencyOptions,
    icon: 'fa fa-coins',
    exclusiveFor: ['White Label', 'Consultant'],
    style: { width: '25vw' },
  };
  formOptions.billingType = {
    type: 'toggle',
    title: 'Billing Type',
    options: ['Monthly', 'Instant Billing'],
    exclusiveFor: ['White Label', 'Consultant'],
    icon: 'fa fa-calendar',
  };
  formOptions.expectedStartDate = {
    type: 'date',
    title: 'Expected Start Date',
    icon: 'fa fa-calendar',
    tooltipText: 'This field is automatically populated when the customer signs the contract.',
  };
  formOptions.expectedDeliveryDate = {
    type: 'select',
    title: 'Expected Delivery Date',
    icon: 'fa fa-calendar',
    exclusiveFor: ['White Label'],
    options: deliveryWeeks,
    style: { width: '15vw' },
  };
  formOptions.perReportFee = {
    type: 'toggle',
    title: 'Report Fee',
    icon: <Assessment sx={{ marginRight: '20px' }} />,
    exclusiveFor: ['White Label', 'Consultant'],
    options: [
      { title: <i className='fa fa-spin fa-spinner' />, value: '', defaultValue: 'perReportFee' },
      { title: 'Custom Range & Price', value: 'Custom Range & Price' },
      { title: 'Custom Price', value: 'Custom Price' },
    ],
    style: { width: '15vw' },
  };
  formOptions.ambassadorCommissionRate = {
    type: 'toggle',
    title: 'Ambassador Commission Rate',
    icon: 'fa fa-credit-card',
    exclusiveFor: ['Ambassador'],
    options: [
      { title: '10%', value: '10' },
      { title: '20%', value: '20' },
      { title: 'Custom', value: 'custom' },
    ],
    style: { width: '15vw' },
  };
  formOptions.wlDevelopmentFee = {
    type: 'toggle',
    title: 'White Label Development Fee',
    icon: 'fa fa-credit-card',
    exclusiveFor: ['White Label'],
    options: [
      { title: <i className='fa fa-spin fa-spinner' />, value: '', defaultValue: 'wlDevelopmentFee' },
      { title: <i className='fa fa-spin fa-spinner' />, value: '', defaultValue: 'wlDevelopmentFee2' },
      { title: 'Custom', value: 'custom' },
    ],
    style: { width: '15vw' },
  };
  formOptions.billDevFeeOnce = {
    type: 'checkbox',
    title: 'Do Not Split Invoice',
    icon: 'fa fa-credit-card',
    exclusiveFor: ['White Label'],
    tooltipText:
      'Default is to bill 50% at Expected Start Date, 2nd 50% at Delivery date. Check this box to bill the whole amount at start date.',
  };
  formOptions.licenseFee = {
    type: 'toggle',
    title: 'License Fee',
    icon: 'fa fa-credit-card',
    exclusiveFor: ['White Label', 'Consultant'],
    options: [
      { title: <i className='fa fa-spin fa-spinner' />, value: '', defaultValue: 'licenseFee' },
      { title: 'Custom', value: 'custom' },
    ],
    style: { width: '15vw' },
  };
  formOptions.hasPaymentSystem = {
    type: 'select',
    title: 'Has Payment Sytem',
    icon: 'fa fa-shopping-cart',
    exclusiveFor: ['White Label'],
    options: ['Yes', 'No'].map((x, i) => ({ label: x, value: i ? false : true })),
    style: { width: '15vw' },
  };

  const accountTypeOptions = [
    { label: 'White Label', value: 'White Label' },
    { label: 'Consultant', value: 'Consultant' },
    { label: 'Ambassador', value: 'Ambassador' },
  ];

  return {
    contracts: state.admin?.adminPortal?.contracts,
    formOptions,
    adminOrgs: state.admin?.organisations?.map(itemToOptions),
    branding: state?.branding,
    pricingBands,
    fixedRates,
    accountTypeOptions: accountTypeOptions.filter((opt) => {
      if (ambassadorCreator && !contractApprover) return opt.value === 'Ambassador';
      else return opt;
    }),
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveContract: (contract, history) => dispatch(ADMINACTIONS.saveContract(contract, history)),
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesNewContract);

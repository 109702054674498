import { Cancel } from '@mui/icons-material';
import { Checkbox, LinearProgress, Tooltip } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';
import { AddButton, TooltipText } from '../../styledComponents/GlobalStyle';
import AddNewCard from './AddNewCard';

const ListPaymentMethods = ({
  paymentMethods,
  fetchingPmth,
  stripeKey,
  defaultPaymentMethod,
  stripeCustomerId,
  getStripePaymentMethods,
  branding,
  removePaymentMethod,
  updateStripeCustomer,
}) => {
  const { breadCrumbsBackground, tooltipBackground, tooltipText } = branding.colors;
  const stripePromise = stripeKey && loadStripe(stripeKey);
  const [addCard, setAddCard] = useState(false);
  const [defaultMethod, setDefaultMethod] = useState(defaultPaymentMethod);

  console.log(
    'stripePromise',
    stripePromise.then((res) => console.log('res', res))
  );

  useEffect(() => {
    if (stripeCustomerId) {
      (async () => await getStripePaymentMethods())();
    }
  }, [stripeCustomerId, getStripePaymentMethods]);

  const handleChange = async (id) => {
    try {
      setDefaultMethod(id);
      await await updateStripeCustomer('invoice_settings', `{default_payment_method:${id}}`);
    } catch (error) {
      console.log('Error', error);
    }
  };

  if (!stripeCustomerId) return null;

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <section className='panel'>
          <aside className='profile-nav alt'>
            <section className='panel'>
              <header className='panel-heading'>
                <i className={'fa fa-credit-card'} style={{ paddingRight: 20 }} />
                Payment Method
                {(fetchingPmth || !paymentMethods) && <LinearProgress color='inherit' sx={{ marginTop: 5 }} />}
                {paymentMethods && (
                  <div>
                    <div
                      style={{
                        display: paymentMethods?.length === 0 && 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      {Array.isArray(paymentMethods) && paymentMethods?.length === 0 && stripePromise ? (
                        <Elements stripe={stripePromise}>
                          <AddNewCard />
                        </Elements>
                      ) : (
                        Array.isArray(paymentMethods) &&
                        paymentMethods?.length > 0 && (
                          <ul style={{ width: '100%', listStyleType: 'none', padding: 0, margin: 0 }}>
                            {paymentMethods?.map((pOpt, index) => (
                              <li key={index}>
                                <div className='payment-method-options_list_wrapper'>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <i className={'fa fa-credit-card'} style={{ padding: 20, fontSize: 25 }} />
                                    <div style={{ marginLeft: '1vw' }}>
                                      <p style={{ fontWeight: 'bold', fontSize: 16, margin: 0 }}>
                                        {pOpt?.card?.brand} ending in{' '}
                                        <span style={{ color: breadCrumbsBackground }}>{pOpt?.card?.last4}</span>
                                      </p>
                                      <p style={{ marginTop: -10 }}>
                                        Expires in {pOpt?.card?.exp_month}/{pOpt?.card?.exp_year}
                                      </p>
                                    </div>
                                  </div>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {pOpt?.id === (defaultMethod || defaultPaymentMethod) ? (
                                      <Checkbox
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 25 /*marginRight: 5*/ } }}
                                        checked={pOpt?.id === (defaultMethod || defaultPaymentMethod)}
                                        onChange={() => handleChange(pOpt?.id)}
                                      />
                                    ) : (
                                      <Tooltip
                                        sx={{ fontSize: 20, cursor: 'pointer' }}
                                        title={<TooltipText>Set Default Payment Method</TooltipText>}
                                        arrow
                                        componentsProps={{
                                          tooltip: { sx: { bgcolor: tooltipBackground, color: tooltipText } },
                                          arrow: { sx: { color: tooltipBackground } },
                                        }}>
                                        <Checkbox
                                          sx={{ '& .MuiSvgIcon-root': { fontSize: 25 /*marginRight: 5*/ } }}
                                          checked={pOpt?.id === (defaultMethod || defaultPaymentMethod)}
                                          onChange={() => handleChange(pOpt?.id)}
                                        />
                                      </Tooltip>
                                    )}
                                    {pOpt?.id !== (defaultMethod || defaultPaymentMethod) && (
                                      <Tooltip
                                        sx={{ fontSize: 20, cursor: 'pointer' }}
                                        title={<TooltipText>Delete Payment Method</TooltipText>}
                                        arrow
                                        componentsProps={{
                                          tooltip: { sx: { bgcolor: tooltipBackground, color: tooltipText } },
                                          arrow: { sx: { color: tooltipBackground } },
                                        }}>
                                        <Cancel sx={{ cursor: 'pointer' }} onClick={() => removePaymentMethod(pOpt?.id)} />
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ))}
                            {addCard && stripePromise && (
                              <Elements stripe={stripePromise}>
                                <AddNewCard setAddCard={setAddCard} addCard={addCard} />
                              </Elements>
                            )}
                            <AddButton
                              className='btn btn-success'
                              style={{ marginTop: 20 }}
                              onClick={() => setAddCard(!addCard)}>
                              {!addCard ? 'Add Another Card' : 'Cancel'}
                            </AddButton>
                          </ul>
                        )
                      )}
                    </div>
                  </div>
                )}
              </header>
            </section>
          </aside>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    stripeCustomerId,
    stripeSubscriptions,
    stripeKey,
    stripePaymentMethods: { paymentMethods, loader, defaultPaymentMethod } = {},
  } = state.payment.stripe || {};

  return {
    branding: state.branding,
    paymentMethods: paymentMethods && _.orderBy(paymentMethods, (item) => item.created && new Date(item.created), 'desc'),
    fetchingPmth: loader,
    stripeKey,
    defaultPaymentMethod,
    stripeSubscriptions,
    stripeCustomerId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveContract: (contract, history) => dispatch(ADMINACTIONS.saveContract(contract, history)),
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  getStripePaymentMethods: () => dispatch(PAYMENTACTIONS.getStripePaymentMethods()),
  updateStripeSubscriptions: (subId, field, value) =>
    dispatch(PAYMENTACTIONS.updateStripeSubscriptions(subId, field, value)),
  removePaymentMethod: (id) => dispatch(PAYMENTACTIONS.removePaymentMethod(id)),
  updateStripeCustomer: (field, value) => dispatch(PAYMENTACTIONS.updateStripeCustomer(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListPaymentMethods);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import Select from 'react-select';
import { customStyles } from '../codeStyles';
import Explainer from './Explainer';
import _ from 'lodash';
import { toggleModal } from '../../store/actions/adminActions';
import Tooltip from '@mui/material/Tooltip';
import { bulkAddOptionsToolTipContent } from '../utils/TooltipContent';
import { LinearProgress } from '@mui/material';
import { TooltipText } from '../../styledComponents/GlobalStyle';

const TrackingOptions = ({
  getTrackingCategories,
  selectTrackingCategory,
  trackingCategories,
  selectedTrackingCategory,
  trackingOptions,
  selectedTrackingOptions,
  method,
  tenant,
  entities,
  selectTrackingOption,
  addTrackingOption,
  branding,
  wlSettings,
  toggleModal,
  showLoader,
}) => {
  const { tooltipBackground, tooltipText, primaryColor } = branding.colors || {};

  useEffect(() => {
    if (method === 'trackingcategories') {
      getTrackingCategories();
    }
  }, [method, tenant, getTrackingCategories]);

  const handleTrackingCategory = (selectedCategory) => {
    selectTrackingCategory(selectedCategory);
  };

  const handleAddTrackingOption = (category, item) => {
    // if tracking option with same name exists use that one.
    const optionName = `${item.name} CYF`;
    const exists = _.find(trackingOptions, { label: optionName });

    if (exists) {
      selectTrackingOption(item.id, exists);
    } else {
      addTrackingOption(category, item);
    }
  };

  const handleTrackingOptionChange = (id, selectedOption, e) => {
    selectTrackingOption(id, selectedOption, e?.action);
  };

  if (!trackingCategories || trackingCategories?.length < 1) {
    return (
      <>
        <li style={{ padding: '10px 15px' }}>
          You currently have no tracking categories in XERO. Learn how you can use XERO Tracking Categories to speed up your
          Compare Your Footprint Import.
          <p>
            Please enable tracking categories in XERO.{' '}
            <a href='https://central.xero.com/s/article/Set-up-tracking-categories-GL'>Instructions</a>
          </p>
        </li>
      </>
    );
  }

  return [
    <li key='trackingCategory' style={{ padding: '10px 15px' }}>
      Which tracking category contains Carbon Footprint Categories?
      <span className='pull-right' style={{ marginRight: '47px', lineHeight: '0' }}>
        <Select
          id='selectXeroCategory'
          value={selectedTrackingCategory}
          onChange={handleTrackingCategory}
          options={trackingCategories}
          styles={customStyles}
        />
      </span>
    </li>,

    selectedTrackingCategory && entities && (
      <>
        <li style={{ display: 'flex', padding: '10px 15px', marginRight: '45px', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Bulk Add Tracking Options{' '}
            <Tooltip
              title={<TooltipText>{bulkAddOptionsToolTipContent(wlSettings)}</TooltipText>}
              placement='right'
              arrow
              componentsProps={{
                tooltip: { sx: { backgroundColor: tooltipBackground, color: tooltipText } },
                arrow: { sx: { color: tooltipBackground } },
              }}>
              <i
                className='fa fa-question-circle'
                style={{ fontSize: 16, cursor: 'pointer', color: primaryColor, marginLeft: 5 }}
              />
            </Tooltip>
          </div>

          <div
            id='bulkAddTrackingOptions'
            className='btn btn-success'
            style={{ width: 287.594 }}
            disabled={showLoader}
            onClick={() => {
              toggleModal(true, 'saveXero-bulkOptions', {
                selectedTrackingCategory,
                tenant,
              });
            }}>
            Bulk Add
          </div>
        </li>
      </>
    ),

    showLoader && <LinearProgress color='inherit' />,

    selectedTrackingCategory &&
      !showLoader &&
      entities &&
      entities.map((item) => (
        <li key={item.id} id={`spendEntity-${item.id}`} style={{ padding: '10px 15px' }}>
          Select your{' '}
          <Explainer id={`spendEntity-${item.id}`} description={item.description}>
            {item.name}
          </Explainer>{' '}
          Tracking Option
          {(!selectedTrackingOptions || !selectedTrackingOptions[item.id]) && (
            <span
              className='btn btn-success pull-right'
              style={{ cursor: 'pointer' }}
              disabled={showLoader}
              onClick={(e) => handleAddTrackingOption(selectedTrackingCategory.value, item)}>
              <i className='fa fa-plus' />
            </span>
          )}
          <span
            className='pull-right'
            style={{
              marginRight: selectedTrackingOptions?.[item.id] ? '47px' : '10px',
              lineHeight: '0',
            }}>
            <Select
              className='spendEntitySelect'
              id={`spendEntity-${item.id}-select`}
              value={selectedTrackingOptions?.[item.id] || false}
              onChange={(value, e) => handleTrackingOptionChange(item.id, value, e)}
              options={trackingOptions}
              styles={customStyles}
              isClearable={true}
            />
          </span>
        </li>
      )),
  ];
};

const mapStateToProps = (state) => {
  const { selectedTrackingOptions } = state || {};

  return {
    method: state.method,
    tenant: state.tenant,
    branding: state.branding,
    trackingCategories: state.trackingCategories,
    selectedTrackingCategory: state?.selectedTrackingCategory,
    trackingOptions: _.sortBy(state?.selectedTrackingCategory?.nestedOptions, ['label']),
    selectedTrackingOptions: selectedTrackingOptions,
    entities: _.sortBy(state.spendEntities, ['name']),
    wlSettings: state.whiteLabelAdmin,
    showLoader: state.showLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTrackingCategories: () => dispatch(ACTIONS.getTrackingCategories()),
  selectTrackingCategory: (selected) => dispatch(ACTIONS.selectTrackingCategory(selected)),
  selectTrackingOption: (option, value, action) => dispatch(ACTIONS.setTrackingOption(option, value, action)),
  addTrackingOption: (option, value) => dispatch(ACTIONS.addTrackingOption(option, value)),
  toggleModal: (status, action, item, modalProps) => dispatch(toggleModal(status, action, item, modalProps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingOptions);

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ConsumptionValue from './ConsumptionValue';
import { optionCSS } from 'react-select/dist/index-f91a2068.cjs.prod';

const ConsumptionRowWithCO2e = ({
  years,
  months,
  getYears,
  organisationDetails,
  metricValues,
  metrics,
  industries,
  subentity,
  option,
  usageDetail,
  facility,
  currentOrganisation,
  reportYear,
}) => {
  /* const usageMetricID = Object?.values(option)?.[0]?.metric; */
  let usageMetricID;
  years.forEach((year) => {
    if (Object?.values(option)?.[0]?.[year]?.metric !== undefined) {
      usageMetricID = Object?.values(option)?.[0]?.[year]?.metric;
    }
  });
  const usageMetric = metrics && _.find(metrics, { id: usageMetricID });

  const calculateChange = (yearValue, prevYearValue) => {
    if (!prevYearValue) return null;

    const change = yearValue / prevYearValue - 1;

    return change * 100;
  };

  const hasMarketFactors =
    Object?.keys(option)[0] === currentOrganisation
      ? Object?.keys(option?.[currentOrganisation])?.find(
          (yr) =>
            option[currentOrganisation][yr].market_mix ||
            (option[currentOrganisation][yr].co2e_market &&
              option[currentOrganisation][yr].co2e_market != option[currentOrganisation][yr].co2e)
        )
      : Object?.keys(option)?.find(
          (yr) => option[yr].market_mix || (option[yr].co2e_market && option[yr].co2e_market != option[yr].co2e)
        );

  if (months.length > 0) {
    /********************
     * For Monthly Report *
     ********************/

    return [
      <tr
        key={`usage`}
        className={`table-values ${facility ? `facility-values toggle-facility-${facility.id}` : ''}`}
        style={{ background: 'rgba(0,0,0,0.05)' }}>
        <td style={{ textAlign: 'left' }}>
          {' '}
          {facility ? facility.name : `${subentity.name} (${usageMetric && usageMetric.name})`}
          <span className='usage-detail'>{facility ? `consumption` : usageDetail}</span>
        </td>

        {metricValues &&
          ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
            return getYears.map((year) => {
              return (
                <>
                  <ConsumptionValue
                    //key={`Absolute-${month}`}
                    value={
                      option[currentOrganisation][`${year}-${month}`]
                        ? option[currentOrganisation][`${year}-${month}`].total
                        : 0
                    }
                    change={
                      option[currentOrganisation][`${year}-${month}`] && option[currentOrganisation][`${year - 1}-${month}`]
                        ? (option[currentOrganisation][`${year}-${month}`].total /
                            option[currentOrganisation][`${year - 1}-${month}`].total) *
                            100 -
                          100
                        : null
                    }
                    classValue={`toggle-absolute toggle-month-${month} toggle-year-${year}`}
                  />
                </>
              );
            });
          })}

        {metricValues &&
          ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
            return getYears.map((year) => {
              const metricValuesThisYear = metricValues.find(
                (metrics) => metrics[`${year}-${month}`] === `${year}-${month}`
              );
              const metricValuesPrevYear = metricValues.find(
                (metrics) => metrics[`${year}-${month}`] === `${year - 1}-${month}`
              );
              //do consts

              return (
                <>
                  <ConsumptionValue
                    //key={`Revenue-${month}`}
                    value={option[`${year}-${month}`] ? option[`${year}-${month}`].total : 0}
                    prev={option[`${year - 1}-${month}`] ? option[`${year - 1}-${month}`].total : 0}
                    withNormalisation={true}
                    normalise={
                      metricValuesThisYear &&
                      metricValuesThisYear.originalTurnover &&
                      metricValuesThisYear.originalTurnover / 1000000
                    }
                    normalisePrev={
                      metricValuesPrevYear &&
                      metricValuesPrevYear.originalTurnover &&
                      metricValuesPrevYear.originalTurnover / 1000000
                    }
                    classValue={`toggle-revenue toggle-month-${month} toggle-year-${year}`}
                  />
                </>
              );
            });
          })}

        {
          // Normalised by metrics1
          metricValues &&
            ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
              const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
              const metricID = orgIndustry?.[ir];
              const metric = metrics && metrics.find((metric) => metric.id === metricID);

              return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return (
                  metric &&
                  metricID !== '230393' &&
                  getYears.map((year) => {
                    const metricValuesThisYear = metricValues.find(
                      (metrics) => metrics[`${year}-${month}`] === `${year}-${month}`
                    );
                    const metricValuesPrevYear = metricValues.find(
                      (metrics) => metrics[`${year}-${month}`] === `${year - 1}-${month}`
                    );

                    return (
                      <ConsumptionValue
                        value={option[`${year}-${month}`] ? option[`${year}-${month}`].total : 0}
                        prev={option[`${year - 1}-${month}`] ? option[`${year - 1}-${month}`].total : 0}
                        withNormalisation={ir}
                        normalise={metricValuesThisYear && metricValuesThisYear[`metric${index + 1}`]}
                        normalisePrev={metricValuesPrevYear && metricValuesPrevYear[`metric${index + 1}`]}
                        classValue={`toggle-m${index + 1} toggle-month-${month} toggle-year-${year}`}
                      />
                    );
                  })
                );
              });
            })
        }
      </tr>,
      <tr
        key={`co2e`}
        className={`table-values ${facility ? `facility-values toggle-facility-${facility.id}` : ''} ${
          hasMarketFactors ? `toggle-location` : ''
        } `}
        style={{ background: 'rgba(0,0,0,0.0)' }}>
        <td style={{ textAlign: 'left' }}>
          {facility ? facility.name : ''}
          <span className='usage-detail' style={{ textTransform: 'none' }}>
            t CO2e {hasMarketFactors ? `Location Based` : ``}
          </span>
        </td>

        {
          // Normalised by Absolute tC02e

          ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
            return (
              metricValues &&
              getYears.map((year) => {
                return (
                  <>
                    <ConsumptionValue
                      //key={month}
                      value={option[`${year}-${month}`] ? option[`${year}-${month}`].co2e : 0}
                      change={
                        option[`${year}-${month}`] && option[`${year - 1}-${month}`]
                          ? (option[`${year}-${month}`].co2e / option[`${year - 1}-${month}`].co2e) * 100 - 100
                          : null
                      }
                      classValue={`toggle-absolute toggle-month-${month} toggle-year-${year}`}
                    />
                  </>
                );
              })
            );
          })
        }

        {
          // Normalised by revenue tC02e

          metricValues &&
            ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
              return getYears.map((year) => {
                const metricValuesThisYear = metricValues.find(
                  (metrics) => metrics[`${year}-${month}`] === `${year}-${month}`
                );
                const metricValuesPrevYear = metricValues.find(
                  (metrics) => metrics[`${year}-${month}`] === `${year - 1}-${month}`
                );

                return (
                  <ConsumptionValue
                    //key={month}
                    value={option[`${year}-${month}`] ? option[`${year}-${month}`].co2e : 0}
                    prev={option[`${year - 1}-${month}`] ? option[`${year - 1}-${month}`].co2e : 0}
                    withNormalisation={true}
                    normalise={
                      metricValuesThisYear &&
                      metricValuesThisYear.originalTurnover &&
                      metricValuesThisYear.originalTurnover / 1000000
                    }
                    normalisePrev={
                      metricValuesPrevYear &&
                      metricValuesPrevYear.originalTurnover &&
                      metricValuesPrevYear.originalTurnover / 1000000
                    }
                    classValue={`toggle-revenue toggle-month-${month} toggle-year-${year}`}
                  />
                );
              });
            })
        }

        {
          // Normalised by metrics2 tC02e
          metricValues &&
            ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
              const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
              const metricID = orgIndustry?.[ir];
              const metric = metrics && metrics.find((metric) => metric.id === metricID);

              return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return (
                  metric &&
                  metricID !== '230393' &&
                  getYears.map((year) => {
                    const metricValuesThisYear = metricValues.find(
                      (metrics) => metrics[`${year}-${month}`] === `${year}-${month}`
                    );
                    const metricValuesPrevYear = metricValues.find(
                      (metrics) => metrics[`${year}-${month}`] === `${year - 1}-${month}`
                    );

                    return (
                      <ConsumptionValue
                        //key={month}
                        value={option[`${year}-${month}`] ? option[`${year}-${month}`].co2e : 0}
                        prev={option[`${year - 1}-${month}`] ? option[`${year - 1}-${month}`].co2e : 0}
                        withNormalisation={ir}
                        normalise={metricValuesThisYear && metricValuesThisYear[`metric${index + 1}`]}
                        normalisePrev={metricValuesPrevYear && metricValuesPrevYear[`metric${index + 1}`]}
                        classValue={`toggle-m${index + 1} toggle-month-${month} toggle-year-${year}`}
                      />
                    );
                  })
                );
              });
            })
        }
      </tr>,

      hasMarketFactors ? (
        <tr
          key={`co2e_market`}
          className={`table-values ${facility ? `facility-values toggle-facility-${facility.id}` : ''} toggle-market`}
          style={{ background: 'rgba(0,0,0,0.0)' }}>
          <td style={{ textAlign: 'left' }}>
            {facility ? facility.name : ''}
            <span className='usage-detail' style={{ textTransform: 'none' }}>
              t CO2e {hasMarketFactors ? `Market Based` : ``}
            </span>
          </td>

          {
            // Normalised by Absolute tC02e - Market Factors

            ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
              return (
                metricValues &&
                getYears.map((year) => {
                  const co2Value =
                    option[`${year}-${month}`] &&
                    (option[`${year}-${month}`].co2e_market || option[`${year}-${month}`].co2e_market === 0
                      ? option[`${year}-${month}`].co2e_market
                      : option[`${year}-${month}`].co2e);
                  const co2ValuePrev =
                    option[`${year - 1}-${month}`] &&
                    (option[`${year - 1}-${month}`].co2e_market
                      ? option[`${year - 1}-${month}`].co2e_market
                      : option[`${year - 1}-${month}`].co2e);
                  return (
                    <ConsumptionValue
                      //key={month}
                      value={co2Value}
                      change={co2Value && co2ValuePrev ? (co2Value / co2ValuePrev) * 100 - 100 : null}
                      classValue={`toggle-absolute toggle-month-${month} toggle-year-${year}`}
                    />
                  );
                })
              );
            })
          }
          {
            // Normalised by revenue tC02e

            metricValues &&
              ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                return getYears.map((year) => {
                  const metricValuesThisYear = metricValues.find(
                    (metrics) => metrics[`${year}-${month}`] === `${year}-${month}`
                  );
                  const metricValuesPrevYear = metricValues.find(
                    (metrics) => metrics[`${year}-${month}`] === `${year - 1}-${month}`
                  );

                  const co2Value =
                    option[`${year}-${month}`] &&
                    (option[`${year}-${month}`].co2e_market || option[`${year}-${month}`].market_mix
                      ? option[`${year}-${month}`].co2e_market
                      : option[`${year}-${month}`].co2e);
                  const co2ValuePrev =
                    option[`${year - 1}-${month}`] &&
                    (option[`${year - 1}-${month}`].co2e_market || option[`${year - 1}-${month}`].market_mix
                      ? option[`${year - 1}-${month}`].co2e_market
                      : option[`${year - 1}-${month}`].co2e);

                  return (
                    <ConsumptionValue
                      //key={month}
                      value={co2Value}
                      prev={co2ValuePrev}
                      withNormalisation={true}
                      normalise={
                        metricValuesThisYear &&
                        metricValuesThisYear.originalTurnover &&
                        metricValuesThisYear.originalTurnover / 1000000
                      }
                      normalisePrev={
                        metricValuesPrevYear &&
                        metricValuesPrevYear.originalTurnover &&
                        metricValuesPrevYear.originalTurnover / 1000000
                      }
                      classValue={`toggle-revenue toggle-month-${month} toggle-year-${year}`}
                    />
                  );
                });
              })
          }
          {
            // Normalised by metrics2 tC02e
            metricValues &&
              ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
                const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
                const metricID = orgIndustry?.[ir];
                const metric = metrics && metrics.find((metric) => metric.id === metricID);

                return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
                  return (
                    metric &&
                    metricID !== '230393' &&
                    getYears.map((year) => {
                      const metricValuesThisYear = metricValues.find(
                        (metrics) => metrics[`${year}-${month}`] === `${year}-${month}`
                      );
                      const metricValuesPrevYear = metricValues.find(
                        (metrics) => metrics[`${year}-${month}`] === `${year - 1}-${month}`
                      );

                      const co2Value =
                        option[`${year}-${month}`] &&
                        (option[`${year}-${month}`].co2e_market || option[`${year}-${month}`].market_mix
                          ? option[`${year}-${month}`].co2e_market
                          : option[`${year}-${month}`].co2e);
                      const co2ValuePrev =
                        option[`${year - 1}-${month}`] &&
                        (option[`${year - 1}-${month}`].co2e_market || option[`${year - 1}-${month}`].market_mix
                          ? option[`${year - 1}-${month}`].co2e_market
                          : option[`${year - 1}-${month}`].co2e);

                      return (
                        <ConsumptionValue
                          //key={month}
                          value={co2Value}
                          prev={co2ValuePrev}
                          withNormalisation={ir}
                          normalise={metricValuesThisYear && metricValuesThisYear[`metric${index + 1}`]}
                          normalisePrev={metricValuesPrevYear && metricValuesPrevYear[`metric${index + 1}`]}
                          classValue={`toggle-m${index + 1} toggle-month-${month} toggle-year-${year}`}
                        />
                      );
                    })
                  );
                });
              })
          }
        </tr>
      ) : null,
    ];
  }

  return [
    <tr
      key={crypto.randomUUID()}
      id={`${subentity.name.split(' ').join('').replace(':', '-')}-usage-row`}
      className={`table-values ${
        facility
          ? `facility-values toggle-facility-${facility.id} ${subentity.name
              .split(' ')
              .join('')
              .replace(':', '-')}-usage-row`
          : ''
      }`}
      style={{ background: 'rgba(0,0,0,0.05)' }}>
      <td style={{ textAlign: 'left' }}>
        {facility ? facility.name : `${subentity.name} (${usageMetric && usageMetric.name})`}
        <span className='usage-detail'>{facility ? `consumption` : usageDetail}</span>
      </td>

      {metricValues &&
        years.map((year) => {
          return (
            <ConsumptionValue
              key={crypto.randomUUID()}
              year={year}
              value={
                option?.[currentOrganisation]?.[year]
                  ? option?.[currentOrganisation]?.[year].total
                  : option?.[year]
                  ? option?.[year]?.total
                  : 0
              }
              change={calculateChange(
                option?.[currentOrganisation]?.[year] ? option?.[currentOrganisation]?.[year]?.total : option?.[year]?.total,
                option?.[currentOrganisation]?.[year - 1]
                  ? option?.[currentOrganisation]?.[year - 1]?.total
                  : option?.[year - 1]?.total
              )}
              classValue={`toggle-absolute toggle-year-${year}`}
            />
          );
        })}
      {
        // Normalised by revenue
        metricValues &&
          years.map((year) => {
            const metricValuesThisYear = metricValues.find((metrics) => metrics.year === String(year));
            const metricValuesPrevYear = metricValues.find((metrics) => metrics.year === String(year - 1));

            return (
              <ConsumptionValue
                key={crypto.randomUUID()}
                year={year}
                value={
                  option?.[currentOrganisation]?.[year]
                    ? option[currentOrganisation][year].total
                    : option[year]
                    ? option[year].total
                    : 0
                }
                prev={
                  option?.[currentOrganisation]?.[year - 1]
                    ? option[currentOrganisation][year - 1].total
                    : option[year]
                    ? option[year].total
                    : 0
                }
                withNormalisation={true}
                normalise={metricValuesThisYear?.originalTurnover / 1000000}
                normalisePrev={metricValuesPrevYear?.originalTurnover / 1000000}
                classValue={`toggle-revenue toggle-year-${year}`}
              />
            );
          })
      }
      {
        // Normalised by metrics
        metricValues &&
          ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
            const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
            const metricID = orgIndustry?.[ir];
            const metric = metrics && metrics.find((metric) => metric.id === metricID);

            return (
              metric &&
              metricID !== '230393' &&
              years.map((year) => {
                const metricValuesThisYear = metricValues.find((metrics) => metrics.year === String(year));
                const metricValuesPrevYear = metricValues.find((metrics) => metrics.year === String(year - 1));

                return (
                  <ConsumptionValue
                    key={crypto.randomUUID()}
                    year={year}
                    value={
                      option?.[currentOrganisation]?.[year]
                        ? option[currentOrganisation][year].total
                        : option[year]
                        ? option[year].total
                        : 0
                    }
                    prev={
                      option?.[currentOrganisation]?.[year - 1]
                        ? option[currentOrganisation][year - 1].total
                        : option[year - 1]
                        ? option[year - 1].total
                        : 0
                    }
                    withNormalisation={ir}
                    normalise={metricValuesThisYear && metricValuesThisYear[`metric${index + 1}`]}
                    normalisePrev={metricValuesPrevYear && metricValuesPrevYear[`metric${index + 1}`]}
                    classValue={`toggle-m${index + 1} toggle-year-${year}`}
                  />
                );
              })
            );
          })
      }
    </tr>,

    <tr
      key={crypto.randomUUID()}
      id={`${subentity.name.split(' ').join('').replace(':', '-')}-co2e-row`}
      className={`table-values ${
        facility
          ? `facility-values toggle-facility-${facility.id} ${subentity.name.split(' ').join('').replace(':', '-')}-co2e-row`
          : ''
      } ${hasMarketFactors ? `toggle-location` : ''} `}
      style={{ background: 'rgba(0,0,0,0.0)' }}>
      <td style={{ textAlign: 'left' }}>
        {facility ? facility.name : ''}
        <span className='usage-detail' style={{ textTransform: 'none' }}>
          t CO2e {hasMarketFactors ? `Location Based` : ``}
        </span>
      </td>

      {/* Loop on each year and repeat */}
      {years.map((year) => {
        const yearValue =
          option?.[currentOrganisation]?.[year]?.['scope']?.[1] !== undefined
            ? option[currentOrganisation][year]['scope']?.[1] +
              option[currentOrganisation][year]['scope']?.[2] +
              option[currentOrganisation][year]['scope']?.[3]
            : option?.[year]?.['scope']?.[1] !== undefined
            ? option[year]['scope']?.[1] + option[year]['scope']?.[2] + option[year]['scope']?.[3]
            : 0;
        const prevYearValue =
          option?.[currentOrganisation]?.[year - 1] !== undefined
            ? option[currentOrganisation][year - 1]['scope']?.[1] +
              option[currentOrganisation][year - 1]['scope']?.[2] +
              option[currentOrganisation][year - 1]['scope']?.[3]
            : option?.[year - 1]?.['scope']?.[1] !== undefined
            ? option[year - 1]['scope']?.[1] + option[year - 1]['scope']?.[2] + option[year - 1]['scope']?.[3]
            : 0;
        return (
          <ConsumptionValue
            key={crypto.randomUUID()}
            year={year}
            value={yearValue}
            change={calculateChange(yearValue, prevYearValue)}
            classValue={`toggle-absolute toggle-year-${year}`}
          />
        );
      })}
      {
        // Normalised by revenue
        metricValues &&
          years.map((year) => {
            const metricValuesThisYear = metricValues.find((metrics) => metrics.year === String(year));
            const metricValuesPrevYear = metricValues.find((metrics) => metrics.year === String(year - 1));

            return (
              <ConsumptionValue
                key={crypto.randomUUID()}
                year={year}
                value={
                  option?.[currentOrganisation]?.[year]?.co2e !== undefined
                    ? option?.[currentOrganisation]?.[year]?.co2e
                    : option[year]
                    ? option[year].co2e
                    : 0
                }
                prev={
                  option?.[currentOrganisation]?.[year - 1]?.co2e !== undefined
                    ? option?.[currentOrganisation]?.[year - 1]?.co2e
                    : option[year - 1]
                    ? option[year - 1].co2e
                    : 0
                }
                withNormalisation={true}
                normalise={metricValuesThisYear?.originalTurnover / 1000000}
                normalisePrev={metricValuesPrevYear?.originalTurnover / 1000000}
                classValue={`toggle-revenue toggle-year-${year}`}
              />
            );
          })
      }
      {
        // Normalised by metrics
        metricValues &&
          ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
            const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
            const metricID = orgIndustry?.[ir];
            const metric = metrics?.find((metric) => metric.id === metricID);

            return (
              metric &&
              metricID !== '230393' &&
              years.map((year) => {
                const metricValuesThisYear = metricValues.find((metrics) => metrics.year === String(year));
                const metricValuesPrevYear = metricValues.find((metrics) => metrics.year === String(year - 1));

                return (
                  <ConsumptionValue
                    key={crypto.randomUUID()}
                    year={year}
                    value={
                      option?.[currentOrganisation]?.[year]?.co2e !== undefined
                        ? option[currentOrganisation][year].co2e
                        : option[year]
                        ? option[year].co2e
                        : 0
                    }
                    prev={
                      option?.[currentOrganisation]?.[year - 1]?.co2e !== undefined
                        ? option?.[currentOrganisation]?.[year - 1]?.co2e
                        : option[year - 1]
                        ? option[year - 1].co2e
                        : 0
                    }
                    withNormalisation={ir}
                    normalise={metricValuesThisYear?.[`metric${index + 1}`]}
                    normalisePrev={metricValuesPrevYear?.[`metric${index + 1}`]}
                    classValue={`toggle-m${index + 1} toggle-year-${year}`}
                  />
                );
              })
            );
          })
      }
    </tr>,

    hasMarketFactors ? (
      <tr
        key={crypto.randomUUID()}
        id={`${subentity.name.split(' ').join('').replace(':', '-')}-co2e_market-row`}
        className={`table-values ${
          facility
            ? `facility-values toggle-facility-${facility.id} ${subentity.name
                .split(' ')
                .join('')
                .replace(':', '-')}-co2e_market-row`
            : ''
        } toggle-market`}
        style={{ background: 'rgba(0,0,0,0.0)' }}>
        <td style={{ textAlign: 'left' }}>
          {facility ? facility.name : ''}
          <span className='usage-detail' style={{ textTransform: 'none' }}>
            t CO2e {hasMarketFactors ? `Market Based` : ``}
          </span>
        </td>

        {years.map((year) => {
          const co2Value = option?.[currentOrganisation]?.[year]
            ? option[currentOrganisation][year] &&
              (option[currentOrganisation][year].co2e_market || option[currentOrganisation][year].co2e_market === 0
                ? option[currentOrganisation][year].co2e_market
                : option[currentOrganisation][year].co2e)
            : option[year] &&
              (option[year].co2e_market || option[year].co2e_market === 0 ? option[year].co2e_market : option[year].co2e);

          const co2ValuePrev = option?.[currentOrganisation]?.[year - 1]
            ? option[currentOrganisation][year - 1] &&
              (option[currentOrganisation][year - 1].co2e_market
                ? option[currentOrganisation][year - 1].co2e_market
                : option[currentOrganisation][year - 1].co2e)
            : option[year - 1] && (option[year - 1].co2e_market ? option[year - 1].co2e_market : option[year - 1].co2e);

          return (
            <ConsumptionValue
              key={crypto.randomUUID()}
              year={year}
              value={co2Value}
              change={calculateChange(co2Value, co2ValuePrev)}
              classValue={`toggle-absolute toggle-year-${year}`}
            />
          );
        })}

        {
          // Normalised by revenue
          metricValues &&
            years.map((year) => {
              const metricValuesThisYear = metricValues.find((metrics) => metrics.year === String(year));
              const metricValuesPrevYear = metricValues.find((metrics) => metrics.year === String(year - 1));

              const co2Value = option?.[currentOrganisation]?.[year]
                ? option[currentOrganisation][year] &&
                  (option[currentOrganisation][year].co2e_market || option[currentOrganisation][year].market_mix
                    ? option[currentOrganisation][year].co2e_market
                    : option[currentOrganisation][year].co2e)
                : option[year] &&
                  (option[year].co2e_market || option[year].market_mix ? option[year].co2e_market : option[year].co2e);

              const co2ValuePrev = option?.[currentOrganisation]?.[year - 1]
                ? option[currentOrganisation][year - 1] &&
                  (option[currentOrganisation][year - 1].co2e_market
                    ? option[currentOrganisation][year - 1].co2e_market
                    : option[currentOrganisation][year - 1].co2e)
                : option[year - 1] &&
                  (option[year - 1].co2e_market || option[year - 1].market_mix
                    ? option[year - 1].co2e_market
                    : option[year - 1].co2e);

              return (
                <ConsumptionValue
                  key={crypto.randomUUID()}
                  year={year}
                  value={co2Value}
                  prev={co2ValuePrev}
                  withNormalisation={true}
                  normalise={metricValuesThisYear?.originalTurnover / 1000000}
                  normalisePrev={metricValuesPrevYear?.originalTurnover / 1000000}
                  classValue={`toggle-revenue toggle-year-${year}`}
                />
              );
            })
        }
        {
          // Normalised by metrics
          metricValues &&
            ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
              const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
              const metricID = orgIndustry?.[ir];
              const metric = metrics && metrics.find((metric) => metric.id === metricID);

              return (
                metric &&
                metricID !== '230393' &&
                years.map((year) => {
                  const metricValuesThisYear = metricValues.find((metrics) => metrics.year === String(year));
                  const metricValuesPrevYear = metricValues.find((metrics) => metrics.year === String(year - 1));

                  const co2Value = option?.[currentOrganisation]?.[year]
                    ? option[currentOrganisation][year] &&
                      (option[currentOrganisation][year].co2e_market || option[currentOrganisation][year].market_mix
                        ? option[currentOrganisation][year].co2e_market
                        : option[currentOrganisation][year].co2e)
                    : option[year] &&
                      (option[year].co2e_market || option[year].market_mix ? option[year].co2e_market : option[year].co2e);

                  const co2ValuePrev = option?.[currentOrganisation]?.[year - 1]
                    ? option[currentOrganisation][year - 1] &&
                      (option[currentOrganisation][year - 1].co2e_market || option[currentOrganisation][year - 1].market_mix
                        ? option[currentOrganisation][year - 1].co2e_market
                        : option[currentOrganisation][year - 1].co2e)
                    : option[year - 1] &&
                      (option[year - 1].co2e_market || option[year - 1].market_mix
                        ? option[year - 1].co2e_market
                        : option[year - 1].co2e);

                  return (
                    <ConsumptionValue
                      key={crypto.randomUUID()}
                      year={year}
                      value={co2Value}
                      prev={co2ValuePrev}
                      withNormalisation={ir}
                      normalise={metricValuesThisYear?.[`metric${index + 1}`]}
                      normalisePrev={metricValuesPrevYear?.[`metric${index + 1}`]}
                      classValue={`toggle-m${index + 1} toggle-year-${year}`}
                    />
                  );
                })
              );
            })
        }
      </tr>
    ) : null,
  ];
};

const mapStateToProps = (state) => {
  const { years, months } = state.consumption;

  const { organisation, metric } = state;
  const currentOrganisation = state?.currentOrganisation;

  const displayYears = [];

  const sortYears = displayYears.sort((a, b) => {
    return a - b;
  });

  const getYears = _.uniq(sortYears);

  return {
    years: years || [],
    months: months || [],
    organisationDetails: organisation?.details,
    metricValues: organisation.metrics
      ? organisation?.metrics?.filter((m) => m.organisationId === currentOrganisation)
      : null,
    metrics: metric?.list,
    industries: organisation?.industries,
    getYears,
    currentOrganisation,
    reportYear: state?.report?.details?.year,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConsumptionRowWithCO2e);

import React from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';

const EmissionsByGreenhouseGasesRow = ({
  report,
  totalEmissionsByScope,
  organisationDetails,
  organisationMetrics,
  scope,
  isMarket,
  org,
}) => {
  if (!report || !organisationDetails || !organisationMetrics || !totalEmissionsByScope) return null;

  const { year } = report;

  const hasComparative = totalEmissionsByScope && totalEmissionsByScope[year - 1];

  const co2eLabel = isMarket ? 'co2e_market' : 'co2e';
  const co2Label = isMarket ? 'co2_market' : 'co2';
  const ch4Label = isMarket ? 'ch4_market' : 'ch4';
  const n2oLabel = isMarket ? 'n2o_market' : 'n2o';

  const getByScope = (label) => {
    let total = 0;
    Object?.keys(totalEmissionsByScope[year])?.forEach((org) => {
      total += (totalEmissionsByScope[year][org][scope] && totalEmissionsByScope[year][org][scope]?.[label]) || 0;
    });
    return total;
  };

  const getComparativeByScope = (label) => {
    let total = 0;
    Object?.keys(hasComparative)?.forEach((org) => {
      total += (hasComparative[org][scope] && hasComparative[org][scope]?.[label]) || 0;
    });
    return total;
  };

  const getAllScopes = (label) => {
    let total = 0;
    Object?.keys(totalEmissionsByScope[year])?.forEach((org) => {
      Object?.keys(totalEmissionsByScope[year][org])?.forEach((scope) => {
        total += (totalEmissionsByScope[year][org][scope] && totalEmissionsByScope[year][org][scope]?.[label]) || 0;
      });
    });
    return total;
  };

  const getAllComparativeScopes = (label) => {
    let total = 0;
    Object?.keys(hasComparative)?.forEach((org) => {
      Object?.keys(hasComparative[org])?.forEach((scope) => {
        total += (hasComparative[org][scope] && hasComparative[org][scope]?.[label]) || 0;
      });
    });
    return total;
  };

  const co2e =
    org === 'all' && scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getByScope(co2eLabel)) || 0
      : org === 'all' && !scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getAllScopes(co2eLabel)) || 0
      : scope
      ? (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          totalEmissionsByScope[year][org][scope] &&
          totalEmissionsByScope[year][org][scope][co2eLabel]) ||
        0
      : (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          _.sumBy(Object.values(totalEmissionsByScope[year][org]), co2eLabel)) ||
        0;

  const co2eComparative =
    hasComparative && org === 'all' && scope
      ? (hasComparative && getComparativeByScope(co2eLabel)) || 0
      : hasComparative && org === 'all' && !scope
      ? (hasComparative && getAllComparativeScopes(co2eLabel)) || 0
      : hasComparative && scope
      ? (hasComparative && hasComparative[org] && hasComparative[org][scope] && hasComparative[org][scope][co2eLabel]) || 0
      : (hasComparative && hasComparative[org] && _.sumBy(Object.values(hasComparative[org]), co2eLabel)) || 0;

  const co2 =
    org === 'all' && scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getByScope(co2Label)) || 0
      : org === 'all' && !scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getAllScopes(co2Label)) || 0
      : scope
      ? (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          totalEmissionsByScope[year][org][scope] &&
          totalEmissionsByScope[year][org][scope][co2Label]) ||
        0
      : (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          _.sumBy(Object.values(totalEmissionsByScope[year][org]), co2Label)) ||
        0;

  const co2Comparative =
    hasComparative && org === 'all' && scope
      ? (hasComparative && getComparativeByScope(co2Label)) || 0
      : hasComparative && org === 'all' && !scope
      ? (hasComparative && getAllComparativeScopes(co2Label)) || 0
      : hasComparative && scope
      ? (hasComparative && hasComparative[org] && hasComparative[org][scope] && hasComparative[org][scope][co2Label]) || 0
      : (hasComparative && hasComparative[org] && _.sumBy(Object.values(hasComparative[org]), co2Label)) || 0;

  const ch4 =
    org === 'all' && scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getByScope(ch4Label)) || 0
      : org === 'all' && !scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getAllScopes(ch4Label)) || 0
      : scope
      ? (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          totalEmissionsByScope[year][org][scope] &&
          totalEmissionsByScope[year][org][scope][ch4Label]) ||
        0
      : (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          _.sumBy(Object.values(totalEmissionsByScope[year][org]), ch4Label)) ||
        0;

  const ch4Comparative =
    hasComparative && org === 'all' && scope
      ? (hasComparative && getComparativeByScope(ch4Label)) || 0
      : hasComparative && org === 'all' && !scope
      ? (hasComparative && getAllComparativeScopes(ch4Label)) || 0
      : hasComparative && scope
      ? (hasComparative && hasComparative[org] && hasComparative[org][scope] && hasComparative[org][scope][ch4Label]) || 0
      : (hasComparative && hasComparative[org] && _.sumBy(Object.values(hasComparative[org]), ch4Label)) || 0;

  const n2o =
    org === 'all' && scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getByScope(n2oLabel)) || 0
      : org === 'all' && !scope
      ? (totalEmissionsByScope && totalEmissionsByScope[year] && getAllScopes(n2oLabel)) || 0
      : scope
      ? (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          totalEmissionsByScope[year][org][scope] &&
          totalEmissionsByScope[year][org][scope][n2oLabel]) ||
        0
      : (totalEmissionsByScope &&
          totalEmissionsByScope[year] &&
          totalEmissionsByScope[year][org] &&
          _.sumBy(Object.values(totalEmissionsByScope[year][org]), n2oLabel)) ||
        0;

  const n2oComparative =
    hasComparative && org === 'all' && scope
      ? (hasComparative && getComparativeByScope(n2oLabel)) || 0
      : hasComparative && org === 'all' && !scope
      ? (hasComparative && getAllComparativeScopes(n2oLabel)) || 0
      : hasComparative && scope
      ? (hasComparative && hasComparative[org] && hasComparative[org][scope] && hasComparative[org][scope][n2oLabel]) || 0
      : (hasComparative && hasComparative[org] && _.sumBy(Object.values(hasComparative[org]), n2oLabel)) || 0;

  return (
    <tr>
      <td align='left'>{scope ? `Scope ${scope}` : `All Scopes`}</td>
      {hasComparative && (
        <td className='toggle-comparative'>
          {parseFloat(co2eComparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}
        </td>
      )}
      <td>{parseFloat(co2e || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}</td>
      {hasComparative && (
        <td className='toggle-comparative'>
          {parseFloat(co2Comparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}
        </td>
      )}
      <td>{parseFloat(co2 || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}</td>
      {hasComparative && (
        <td className='toggle-comparative'>
          {parseFloat(ch4Comparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}
        </td>
      )}
      <td>{parseFloat(ch4 || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}</td>
      {hasComparative && (
        <td className='toggle-comparative'>
          {parseFloat(n2oComparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}
        </td>
      )}
      <td>{parseFloat(n2o || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 })}</td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  const { totalEmissionsByScope, filter, withMarketFactors } = state.consumption || {};

  const { organisation, report } = state;

  const isMarket = withMarketFactors && !filter.includes('market');

  return {
    report: report?.details,
    totalEmissionsByScope: totalEmissionsByScope,
    metrics: state.metric?.list,
    organisationDetails: organisation?.details,
    organisationMetrics: organisation?.metrics,
    metricValues: organisation?.metrics ? organisation.metrics : null,
    isMarket,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsByGreenhouseGasesRow);

import { Auth } from '@aws-amplify/auth';
import { Alert, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Actions as LOGINACTIONS } from '../../store/actions/authenticationActions';
import { AuthBtn } from '../../styledComponents/GlobalStyle';
import LogoSide from '../Register/LogoSide';
import ValidationMessage from '../utils/ValidationMessage';
import AuthDropdown from './AuthDropdown';
import SetNewPassword from './SetNewPassword';

Auth.configure({ authenticationFlowType: 'USER_SRP_AUTH' });

const validationSchema = yup.object().shape({ email: yup.string().email().required('Email is required') });
const validationNewPassword = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])\S{8,}/,
      'Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
    ),
});

const CYFLoginPage = ({
  branding,
  invalidLoginCredentials,
  loginWithAws,
  processing,
  getUserCredentials,
  loggingIn,
  pathname,
  supportEmail,
  hasPaymentSystem,
}) => {
  const { btnTextColor, primaryHeaderText, signInFormBg, primaryColor, tooltipBackground, tooltipText } =
    branding.colors || {};
  const { headers, body } = branding.fonts?.[0].font || {};
  const accountCode = new URLSearchParams(window.location.href).get('code');

  const [editedState, setEditedState] = useState({
    username: '',
    password: '',
  });
  const [resetPassword, setResetPassword] = useState(pathname.includes('resetPassword'));
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState({ password: false, newPassword: false });
  const [error, setError] = useState(null);
  const [showNewPasswordComponent, setShowNewPasswordComponent] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [alertStatus, setAlertStatus] = useState(false);
  const alertMessage =
    'To successfully update your password, please complete the password reset process within 48 hours upon receipt of the verification code.';

  const errorsToDisplay = {};

  if (accountCode) {
    // If we have account code here, it's because an ambassador has just connected their account to stripe.
    // Store the accountCode on local storage.
    localStorage.setItem('accountCode', accountCode);
  }

  validationError?.forEach((error) => {
    if (error.path === 'email') {
      errorsToDisplay['email'] = error.message;
    } else if (error.path === 'newPassword') {
      errorsToDisplay['newPassword'] = error.message;
    }
  });

  const forgotPassword = async () => {
    await validationSchema
      .validate({ email: editedState?.['email'] }, { abortEarly: false })
      .then(async () => {
        try {
          loggingIn(true);
          await Auth.forgotPassword(editedState?.['email'].trim());
          setShowNewPasswordComponent(true);
          loggingIn(false);
        } catch (error) {
          console.log(error);
          loggingIn(false);
          setError(error);
        }
      })
      .catch((error) => {
        console.log('Validation Error', error);
        loggingIn(false);
        setValidationError(error.inner);
      });
  };

  const handleSubmit = async () => {
    try {
      loggingIn(true);
      await Auth.signIn(editedState?.['username']?.trim(), editedState?.['password']?.trim())
        .then(async (user) => {
          console.log('USER', user);
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            //for invite new users
            await validationNewPassword
              .validate({ newPassword: newPassword }, { abortEarly: false })
              .then(async () => {
                await Auth.completeNewPassword(user, newPassword.trim())
                  .then(async (user) => {
                    //sign up for invite new user successful
                    await loginWithAws(user);
                    getUserCredentials();
                  })
                  .catch((e) => {
                    loggingIn(false);
                    console.log('ERROR', e);
                    setError(error);
                  });
              })
              .catch((error) => {
                //sign up for invite new user failed
                console.log('error', error);
                loggingIn(false);
                setValidationError(error.inner);
              });
          } else {
            // user login sucessful
            console.log('user', user);
            loginWithAws(user);
            getUserCredentials();
          }
        })
        .catch((error) => {
          // user login failed
          loggingIn(false);
          console.log('ERROR', error);
          setError(error);
          setValidationError(error.inner);
        });
    } catch (error) {
      //Login failed
      loggingIn(false);
      console.log('signUpError', error);
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setError(err);
    }
  };

  const updateField = (e) => {
    const newEditedState = { ...editedState };
    newEditedState[e.target.name] = e.target.value;
    setEditedState(newEditedState);
  };

  if (showNewPasswordComponent) {
    return <SetNewPassword setReset={setResetPassword} setShowNewPasswordComponent={setShowNewPasswordComponent} />;
  } else if (resetPassword) {
    return (
      <div className='login-body' style={{ height: '100vh', width: '100%', display: 'flex' }}>
        <LogoSide />
        <div className='signInSide' style={{ background: signInFormBg, fontFamily: body }}>
          <div className='signInFields' style={{ width: '35vw' }}>
            <h1 style={{ marginBottom: '1.5vh', color: primaryHeaderText }}>Forgot Password?</h1>
            <div>
              <div
                className='col-xs-12 alert-danger alert'
                style={{ marginBottom: 10, zIndex: 10, display: error ? 'block' : 'none' }}>
                <h5 style={{ textAlign: 'left' }}>Error: {error?.log || error?.message}</h5>
              </div>

              <div style={{ marginBottom: '1.5vh', width: '100%' }}>
                <p style={{ marginBottom: '1.5vh', color: primaryHeaderText }}>
                  Enter your e-mail address below to reset your password.
                </p>
                <input
                  id='email-input'
                  className='form-control placeholder-no-fix'
                  name='email'
                  style={{ height: '5vh' }}
                  onChange={updateField}
                  onClick={() => setAlertStatus(true)}
                  placeholder='Email'
                  type='text'
                />

                {alertStatus && (
                  <Alert
                    severity='warning'
                    onClose={() => setAlertStatus(false)}
                    style={{ marginTop: '3px', fontFamily: body }}>
                    <b>{alertMessage}</b>
                  </Alert>
                )}

                <ValidationMessage message={errorsToDisplay.email} />
              </div>

              <div style={{ fontFamily: headers }}>
                <button
                  className='btn btn-default'
                  id='cancelBtn-forgot-password'
                  onClick={() => {
                    setResetPassword(false);
                    setValidationError(null);
                    setAlertStatus(false);
                  }}>
                  Cancel
                </button>

                <AuthBtn
                  id='forgot-password-submit-button'
                  className='btn btn-success onceonly pull-right'
                  style={{ color: '#FFFF' }}
                  onClick={forgotPassword}>
                  {processing && <i className='fa fa-spin fa-spinner icon-spinner' />}
                  Submit
                </AuthBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='login-body' style={{ height: '100vh', width: '100%', display: 'flex', fontFamily: body }}>
      <LogoSide />
      <div className='signInSide' style={{ background: signInFormBg }}>
        <div className='signInFields' style={{ width: '35vw' }}>
          <h1 style={{ marginBottom: '3vh', color: primaryHeaderText }}>Sign In</h1>
          <form method='post' noValidate='novalidate' onSubmit={handleSubmit}>
            <div>
              <div>
                <div className='input-with-icon-block'>
                  <input
                    id='email'
                    type='text'
                    name='username'
                    className='form-control'
                    placeholder='Email'
                    autoFocus='autofocus'
                    value={editedState['username'] || ''}
                    onChange={updateField}
                    data-lpignore='true'
                    style={{ marginBottom: '3vh', height: '5vh' }}
                  />
                  <Tooltip
                    title={
                      <span style={{ fontSize: 12 }}> Email addresses are case sensitive throughout the application. </span>
                    }
                    arrow
                    placement='left'
                    componentsProps={{
                      tooltip: { sx: { backgroundColor: tooltipBackground, color: tooltipText } },
                      arrow: { sx: { color: tooltipBackground } },
                    }}>
                    <i className='fa fa-info-circle input-with-icon-block__icon' />
                  </Tooltip>
                </div>

                <div className='input-with-icon-block'>
                  <i
                    className={`fa ${showPassword.password ? 'fa-eye' : 'fa-eye-slash'} input-with-icon-block__icon`}
                    onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}
                  />
                  <input
                    id='password'
                    type={showPassword.password ? 'text' : 'password'}
                    className='form-control'
                    name='password'
                    value={editedState && editedState['password']}
                    onChange={updateField}
                    placeholder={pathname.includes('inviteuser') ? 'Temporary Password' : 'Password'}
                    data-lpignore='true'
                    style={{ height: '5vh', marginBottom: '3vh' }}
                  />
                </div>

                {pathname.includes('inviteuser') && (
                  <>
                    <div className='input-with-icon-block' style={{ marginBottom: '1vh' }}>
                      <i
                        className={`fa ${showPassword.newPassword ? 'fa-eye' : 'fa-eye-slash'} input-with-icon-block__icon`}
                        onClick={() => setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })}
                      />
                      <input
                        id='password'
                        type={showPassword.newPassword ? 'text' : 'password'}
                        className='form-control'
                        name='newPassword'
                        style={{ height: '5vh' }}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder='New Password'
                      />
                    </div>
                    {errorsToDisplay.newPassword && (
                      <Alert icon={false} severity='error' style={{ color: 'red' }}>
                        <ValidationMessage style={{ marginBottom: '4px' }} message={errorsToDisplay.newPassword} />
                      </Alert>
                    )}
                  </>
                )}
              </div>

              {(invalidLoginCredentials || error) && (
                <div
                  className='alert-danger alert'
                  style={{
                    marginTop: '3vh',
                    display: pathname.includes('inviteuser') ? 'none' : 'block',
                  }}>
                  <h5 style={{ textAlign: 'left' }}> Incorrect Username or Password</h5>
                  <ul style={{ listStyle: 'disc', paddingLeft: '20px', textAlign: 'left' }}>
                    <li>Are you using the right email address?</li>
                    <li>Is the first letter of your email address in lowercase?</li>
                    <li>If you are unable to log-in, resetPassword your password below.</li>
                  </ul>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4vh' }}>
                <div style={{ width: '45%' }}>
                  <AuthBtn
                    type='submit'
                    disabled={processing || !editedState.username || !editedState.password}
                    className='btn btn-lg btn-login btn-block pull-left'
                    style={{ fontFamily: headers, color: 'white', width: '100%' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSubmit();
                    }}>
                    {processing && <i className='fa fa-spin fa-spinner icon-spinner' id='login-spinner' />}
                    Sign In
                  </AuthBtn>
                </div>
                <AuthDropdown headers={headers} />
              </div>

              <div style={{ marginTop: '2vh', display: 'flex', justifyContent: 'space-between' }}>
                <button
                  id='forgot-password'
                  href='#'
                  onClick={() => setResetPassword(true)}
                  style={{ fontFamily: body, color: btnTextColor, border: 0, background: 'inherit' }}>
                  Forgot Password?
                </button>
                <a href={`mailto:${supportEmail}`} style={{ fontFamily: body, color: btnTextColor, marginLeft: '3.5vw' }}>
                  Need support?
                </a>
                <a
                  href='/register'
                  style={{
                    display: hasPaymentSystem ? 'block' : 'none',
                    cursor: 'pointer',
                    color: btnTextColor,
                  }}
                  target='_blank'
                  rel='noopener noreferrer'>
                  Don't have an account yet?
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const pathname = window.location.pathname;
  const { supportEmail, hasPaymentSystem } = state.whiteLabelAdmin;
  return {
    profile: state?.profile?.details,
    invalidLoginCredentials: state.authentication.invalidLoginCredentials,
    processing: state.authentication.processing,
    branding: state.branding,
    supportEmail,
    pathname,
    hasPaymentSystem,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginWithAws: (username) => dispatch(LOGINACTIONS.loginWithAws(username)),
  loggingIn: (saving) => dispatch(LOGINACTIONS.loggingIn(saving)),
  getUserCredentials: () => dispatch(LOGINACTIONS.getUserCredentials()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CYFLoginPage);

import React, { useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const BenchmarkReport = ({
  chartOptions,
  avgBenchmarkWithPerM2,
  avgBenchmarkWithPerFTE,
  avgBenchmarkWithPerMillion,
  scope1And2PerMillion,
  scope1And2PerM2,
  scope1And2PerFTE,
  ratioPerMillion,
  ratioPerM2,
  ratioPerFTE,
  year,
  orgTurnover,
  organisationDetails,
  orgs,
  orgData,
  adminOrgs,
}) => {
  const ref = useRef(null);

  if (!year || !orgTurnover) return null;

  const formatNumber = (value) => parseFloat(value || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 });

  return (
    <div>
      <h3>Benchmark Report: Emissions intensity against industry benchmark</h3>
      <table className='table table-hover general-table' id='rankTable'>
        <thead>
          <tr>
            <th>Organisation</th>
            <th>Year</th>
            <th> tCO2e Scope 1+2 Intensity</th>
            <th>Industry Benchmark tCO2e Scope 1+2 Intensity (per million $ revenue)</th>
            <th> Scope 1+2 Operational Rating</th>
            <th>Rank</th>
            {avgBenchmarkWithPerFTE &&
              scope1And2PerFTE &&
              scope1And2PerFTE[((<th> tCO2e per FTE</th>), (<th>Industry Average tCO2e per FTE</th>), (<th>Rank</th>))]}
            {avgBenchmarkWithPerM2 &&
              scope1And2PerM2 && [<th> tCO2e per m2</th>, <th>Industry Average tCO2e per m2</th>, <th>Rank</th>]}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{organisationDetails?.name}</td>
            <td>{year}</td>
            <td>{formatNumber(scope1And2PerMillion && scope1And2PerMillion.toFixed(3))}</td>
            <td>{formatNumber(avgBenchmarkWithPerMillion && avgBenchmarkWithPerMillion.toFixed(3))}</td>
            <td>
              {formatNumber(
                avgBenchmarkWithPerMillion && ((scope1And2PerMillion / avgBenchmarkWithPerMillion) * 100).toFixed(3)
              )}
              %
            </td>
            <td>{ratioPerMillion}</td>
            {avgBenchmarkWithPerFTE &&
              scope1And2PerFTE[
                ((<td>{formatNumber(scope1And2PerFTE && scope1And2PerFTE.toFixed(3))}</td>),
                (<td>{formatNumber(avgBenchmarkWithPerFTE && avgBenchmarkWithPerFTE.toFixed(3))}</td>),
                (<td>{ratioPerFTE}</td>))
              ]}
            {avgBenchmarkWithPerM2 &&
              scope1And2PerM2 && [
                <td>{formatNumber(scope1And2PerM2 && scope1And2PerM2.toFixed(3))}</td>,
                <td>{formatNumber(avgBenchmarkWithPerM2 && avgBenchmarkWithPerM2.toFixed(3))}</td>,
                <td>{ratioPerM2}</td>,
              ]}
          </tr>

          {orgs?.map((org, index) => {
            return (
              <tr key={`${org}`}>
                <td>{adminOrgs?.find((o) => o.id === org)?.name}</td>
                <td>{orgData[org]?.year}</td>
                <td>
                  {formatNumber(orgData[org]?.scope1And2PerMillion && orgData[org]?.scope1And2PerMillion.toFixed(3)) || 0}
                </td>
                <td>
                  {formatNumber(
                    orgData[org]?.avgBenchmarkWithPerMillion && orgData[org]?.avgBenchmarkWithPerMillion.toFixed(3)
                  )}
                </td>
                <td>
                  {formatNumber(
                    orgData[org]?.avgBenchmarkWithPerMillion &&
                      ((orgData[org]?.scope1And2PerMillion / orgData[org]?.avgBenchmarkWithPerMillion) * 100).toFixed(3)
                  )}
                  %
                </td>
                <td>{orgData[org]?.ratioPerMillion}</td>
                {orgData[org]?.avgBenchmarkWithPerFTE &&
                  orgData[org]?.scope1And2PerFTE > 0 &&
                  orgData[org]?.scope1And2PerFTE[
                    ((<td>{formatNumber(orgData[org]?.scope1And2PerFTE && orgData[org]?.scope1And2PerFTE.toFixed(3))}</td>),
                    (
                      <td>
                        {formatNumber(
                          orgData[org]?.avgBenchmarkWithPerFTE && orgData[org]?.avgBenchmarkWithPerFTE.toFixed(3)
                        )}
                      </td>
                    ),
                    (<td>{orgData[org]?.ratioPerFTE}</td>))
                  ]}
                {orgData[org]?.avgBenchmarkWithPerM2 &&
                  orgData[org]?.scope1And2PerM2 > 0 && [
                    <td>{formatNumber(orgData[org]?.scope1And2PerM2 && orgData[org]?.scope1And2PerM2.toFixed(3))}</td>,
                    <td>
                      {formatNumber(orgData[org]?.avgBenchmarkWithPerM2 && orgData[org]?.avgBenchmarkWithPerM2.toFixed(3))}
                    </td>,
                    <td>{orgData[org]?.ratioPerM2}</td>,
                  ]}
              </tr>
            );
          })}
        </tbody>
      </table>

      <p>
        Intensity: Organisation’s emissions per million $ revenue
        <br />
        Operational Rating: Ratio of your organisation’s Scope 1+2 intensity compared wit the industry average benchmark
        <br />
        Rank: Ranking of your organisation’s Scope 1+2 intensity within the group of organisations in your industry
        <br />
        FTE: Full Time Equivalent employee
        <br />
        m2: Square metre of floorspace (Gross Internal Area, GIA)
      </p>
      <br></br>

      <HighchartsReact ref={ref} highcharts={Highcharts} options={chartOptions} />

      <br></br>
      <p>
        <small>tCO2e - tonnes of carbon dioxide equivalent;</small>
      </p>
      <p>
        <small>FTE - Full Time Equivalent Employees - one FTE is one person working for one year;</small>
      </p>
      <p>
        <small>Scope - description of types of direct / indirect emissions defined by the GHG Protocol;</small>
      </p>
      <p>
        <small>
          Intensity Ratio - a measure of GHG emissions per unit measure of productivity such as revenue, employees, number of
          products made etc.;
        </small>
      </p>
      <p>
        <small>
          WTT - Well to Tank - this describes the Scope 3 emissions associated with transporting fuel from its origin to the
          site where it is turned into energy.
        </small>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  const organisationDetails = state.organisation?.details;
  const industries = state?.organisation.industries;
  const { totalEmissionsByScope } = state.consumption || {};
  const metrics = state.metric?.list;
  const benchmarkData = state.benchmarkData;
  const orgs = state.report?.includedOrgs;
  const adminOrgs = state.admin?.organisations;
  const organisationList = state.organisation?.list;
  const { cyfAdmin, whiteLabelAdmin, greenElementUser } = state.profile?.details || {};
  const adminUser = cyfAdmin || whiteLabelAdmin || greenElementUser || false;
  const currentOrganisation = state.currentOrganisation;
  const organisations = adminUser ? adminOrgs : organisationList;

  let m2 = 0;
  let fte = 0;
  let revenue;

  let orgData = [];

  if (!benchmarkData || benchmarkData.length == 0 || !organisationDetails) {
    console.log('No Benchmark Data');
    return {};
  }

  const benchmarkWithPerM2 = benchmarkData?.filter((item) => item.scope1and2perm2);
  const avgBenchmarkWithPerM2 =
    benchmarkWithPerM2?.length > 0 &&
    _.sumBy(benchmarkWithPerM2, (item) => Number(item.scope1and2perm2)) / benchmarkWithPerM2.length;

  const benchmarkWithPerFTE = benchmarkData?.filter((item) => item.scope1and2perfte);
  const avgBenchmarkWithPerFTE =
    benchmarkWithPerFTE?.length > 0 &&
    _.sumBy(benchmarkWithPerFTE, (item) => Number(item.scope1and2perfte)) / benchmarkWithPerFTE.length;

  const benchmarkWithPerMillion = benchmarkData?.filter((item) => item.scope1and2perm);
  const avgBenchmarkWithPerMillion =
    benchmarkWithPerMillion?.length > 0 &&
    _.sumBy(benchmarkWithPerMillion, (item) => Number(item.scope1and2perm)) / benchmarkWithPerMillion.length;

  const year = state.report?.details?.year;
  const organisationMetrics = state.organisation?.metrics?.find(
    (item) => item.year == year && item.organisationId == currentOrganisation
  );

  const orgTurnover = organisationMetrics?.turnover || organisationMetrics?.originalTurnover;

  organisationMetrics &&
    organisationDetails &&
    ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].forEach((ir, index) => {
      const orgIndustry = _.find(industries, { id: organisationDetails?.industry });
      const metricID = orgIndustry?.[ir];
      const metric = metrics?.find((metric) => metric.id === metricID);
      const metricName = metric && (metric.name === 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

      const metricValue = organisationMetrics[`metric${index + 1}`];

      if (metricName === 'm2') m2 = metricValue;

      if (metricName === 'fte') fte = metricValue;
    });

  const totalEmissionsByScopeThisYear = totalEmissionsByScope?.[year];

  const scope1And2 =
    totalEmissionsByScopeThisYear &&
    (totalEmissionsByScopeThisYear?.[currentOrganisation]?.[1]?.co2e || 0) +
      (totalEmissionsByScopeThisYear?.[currentOrganisation]?.[2]?.co2e || 0);
  const scope3 = totalEmissionsByScopeThisYear && totalEmissionsByScopeThisYear?.[currentOrganisation]?.[3]?.co2e;

  switch (organisationDetails.currency) {
    case 'fdef9fed-ec38-4386-aeb5-63bf8a3b74c6':
      revenue = orgTurnover / 1000000;
      break;

    case 'e0cd9159-e2c5-4142-8e96-d6cc0ca40d5c':
      revenue = orgTurnover / 1000000;
      break;

    case '6720071a-385b-4421-81f6-b9dd3cf36f90':
      revenue = orgTurnover / 1000000;
      break;

    default:
      revenue = orgTurnover / 1000000;
  }

  const scope1And2PerMillion = revenue > 0 && scope1And2 / revenue;
  const scope1And2PerM2 = m2 > 0 && scope1And2 / m2;
  const scope1And2PerFTE = fte > 0 && scope1And2 / fte;
  const { chart1, chart2 } = state.branding?.colors;

  const positionPerMillion =
    benchmarkWithPerMillion?.filter((item) => item.scope1and2perm < scope1And2PerMillion).length + 1 || 1;
  const positionPerM2 = benchmarkWithPerM2?.filter((item) => item.scope1and2perm2 < scope1And2PerM2).length + 1 || 1;
  const positionPerFTE = benchmarkWithPerFTE?.filter((item) => item.scope1and2perfte < scope1And2PerFTE).length + 1 || 1;

  const ratioPerMillion = `${positionPerMillion}:${(benchmarkWithPerMillion && benchmarkWithPerMillion.length + 1) || 1}`;
  const ratioPerM2 = `${positionPerM2}:${(benchmarkWithPerM2 && benchmarkWithPerM2.length + 1) || 1}`;
  const ratioPerFTE = `${positionPerFTE}:${(benchmarkWithPerFTE && benchmarkWithPerFTE.length + 1) || 1}`;

  orgs?.forEach((org) => {
    const orgDetails = organisations?.find((organisation) => organisation.id == org);

    if (!benchmarkData || benchmarkData.length == 0 || !orgDetails) {
      console.log('No Benchmark Data');
      return {};
    }

    const benchmarkWithPerM2 = benchmarkData?.filter((item) => item.scope1and2perm2);
    const avgBenchmarkWithPerM2 =
      benchmarkWithPerM2?.length > 0 &&
      _.sumBy(benchmarkWithPerM2, (item) => Number(item.scope1and2perm2)) / benchmarkWithPerM2.length;

    const benchmarkWithPerFTE = benchmarkData?.filter((item) => item.scope1and2perfte);
    const avgBenchmarkWithPerFTE =
      benchmarkWithPerFTE?.length > 0 &&
      _.sumBy(benchmarkWithPerFTE, (item) => Number(item.scope1and2perfte)) / benchmarkWithPerFTE.length;

    const benchmarkWithPerMillion = benchmarkData?.filter((item) => item.scope1and2perm);
    const avgBenchmarkWithPerMillion =
      benchmarkWithPerMillion?.length > 0 &&
      _.sumBy(benchmarkWithPerMillion, (item) => Number(item.scope1and2perm)) / benchmarkWithPerMillion.length;

    const year = state.report?.details?.year;
    const organisationMetrics = state.organisation?.metrics?.find((item) => item.year == year && item.organisationId == org);

    const orgTurnover = organisationMetrics?.turnover || organisationMetrics?.originalTurnover;

    organisationMetrics &&
      orgDetails &&
      ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
        const orgIndustry = _.find(industries, { id: orgDetails?.industry });
        const metricID = orgIndustry?.[ir];
        const metric = metrics?.find((metric) => metric.id == metricID);
        const metricName = metric && (metric.name == 'Unit' ? orgIndustry.descriptionOfNonStandardRatio : metric.name);

        const metricValue = organisationMetrics[`metric${index + 1}`];

        if (metricName == 'm2') m2 = metricValue;

        if (metricName == 'fte') fte = metricValue;
      });

    const totalEmissionsByScopeThisYear = totalEmissionsByScope?.[year];

    const scope1And2 =
      totalEmissionsByScopeThisYear &&
      (totalEmissionsByScopeThisYear?.[org]?.[1]?.co2e || 0) + (totalEmissionsByScopeThisYear?.[org]?.[2]?.co2e || 0);
    const scope3 = totalEmissionsByScopeThisYear && totalEmissionsByScopeThisYear?.[org]?.[3]?.co2e;

    switch (orgDetails.currency) {
      case 'fdef9fed-ec38-4386-aeb5-63bf8a3b74c6':
        revenue = orgTurnover / 1000000;
        break;

      case 'e0cd9159-e2c5-4142-8e96-d6cc0ca40d5c':
        revenue = orgTurnover / 1000000;
        break;

      case '6720071a-385b-4421-81f6-b9dd3cf36f90':
        revenue = orgTurnover / 1000000;
        break;

      default:
        revenue = orgTurnover / 1000000;
    }

    const scope1And2PerMillion = revenue > 0 && scope1And2 / revenue;
    const scope1And2PerM2 = m2 > 0 && scope1And2 / m2;
    const scope1And2PerFTE = fte > 0 && scope1And2 / fte;

    const positionPerMillion =
      benchmarkWithPerMillion?.filter((item) => item.scope1and2perm < scope1And2PerMillion).length + 1 || 1;
    const positionPerM2 = benchmarkWithPerM2?.filter((item) => item.scope1and2perm2 < scope1And2PerM2).length + 1 || 1;
    const positionPerFTE = benchmarkWithPerFTE?.filter((item) => item.scope1and2perfte < scope1And2PerFTE).length + 1 || 1;

    const ratioPerMillion = `${positionPerMillion}:${(benchmarkWithPerMillion && benchmarkWithPerMillion.length + 1) || 1}`;
    const ratioPerM2 = `${positionPerM2}:${(benchmarkWithPerM2 && benchmarkWithPerM2.length + 1) || 1}`;
    const ratioPerFTE = `${positionPerFTE}:${(benchmarkWithPerFTE && benchmarkWithPerFTE.length + 1) || 1}`;

    orgData[org] = {
      benchmarkWithPerMillion,
      benchmarkWithPerM2,
      benchmarkWithPerFTE,
      avgBenchmarkWithPerMillion,
      avgBenchmarkWithPerM2,
      avgBenchmarkWithPerFTE,
      scope1And2PerMillion,
      scope1And2PerM2,
      scope1And2PerFTE,
      ratioPerMillion,
      ratioPerM2,
      ratioPerFTE,
      positionPerFTE,
      positionPerMillion,
      positionPerM2,
      year,
      organisationDetails: orgDetails,
      orgTurnover,
    };
  });

  const chartData = benchmarkWithPerMillion.map((item, i) => ({
    name: `Org ${i + 1}`,
    y: parseFloat(item.scope1and2perm) || 0,
    color: chart2,
  }));

  chartData.push({
    name: organisationDetails && organisationDetails.name,
    y: parseFloat((scope1And2PerMillion && scope1And2PerMillion.toFixed(2)) || 0),
    color: chart1,
    show: organisationDetails && organisationDetails.name + ': ',
  });

  if (orgs?.length > 0) {
    orgs?.forEach((org) => {
      chartData.push({
        name: organisations?.find((o) => o.id === org)?.name,
        y: parseFloat((orgData[org]?.scope1And2PerMillion && orgData[org]?.scope1And2PerMillion.toFixed(2)) || 0),
        color: chart1,
      });
    });
  }

  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column',
    },
    title: {
      text: `Tonnes CO2e per million revenue`,
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
    },
    yAxis: [
      {
        title: {
          text: '',
        },
        showFirstLabel: false,
      },
    ],

    xAxis: {
      type: 'category',
      labels: {
        enabled: false,
      },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '{point.y}',
        },
      },
    },

    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 0,
      y: 100,
    },
    series: [
      {
        //color: 'rgb(158, 159, 163)',
        data: _.orderBy(chartData, 'y'),
        name: '',
        pointWidth: 50,
        showInLegend: false,
      },
    ],
    // series: [{ data: _.orderBy(chartData,'y')}],
  };

  return {
    chartOptions,
    year,
    avgBenchmarkWithPerM2,
    avgBenchmarkWithPerFTE,
    avgBenchmarkWithPerMillion,
    scope1And2PerMillion: scope1And2PerMillion ? scope1And2PerMillion : 0,
    scope1And2PerM2,
    scope1And2PerFTE,
    ratioPerMillion,
    ratioPerM2,
    ratioPerFTE,
    orgTurnover,
    organisationDetails,
    orgs,
    orgData,
    adminOrgs: organisations,
  };
};

export default connect(mapStateToProps, {})(BenchmarkReport);

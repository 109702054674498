import LinearProgress from '@mui/material/LinearProgress';
import _ from 'lodash';
import moment from 'moment';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { adminPortalSelect } from '../utils/ReactSelectStyles';
import EntityData from './EntityData';
import { CustomOption } from '../Common/ReusableComponents/Reusable';
import { Waveform } from '@uiball/loaders';

const UsageTable = ({
  usageTotals,
  branding,
  facilityOptions,
  currentOrganisation,
  getItemsByTermsFromIndexExport,
  cyfAdmin,
  orgDetails,
  getUsageTotals,
  loading,
  usageDataYear,
  entityTable,
}) => {
  const [usageCategory, setUsageCategory] = useState();
  const [facility, setFacility] = useState();
  const [reportingYear, setReportingYear] = useState(usageDataYear);
  const [reportingYearOptions, setReportingYearOptions] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [newTotals, setNewTotals] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const { headers } = branding.fonts?.[0].font;

  const updateFilteredData = () => {
    const filter = { ...filters };
    let filteredData = [...usageTotals];

    if (usageCategory) {
      filter.entity = usageCategory;
      filteredData = filteredData.filter((item) => item.entity === usageCategory);
    } else if (filter?.entity && !usageCategory) {
      delete filter.entity;
    }

    if (facility) {
      filter.facility = facility;
      filteredData = filteredData.filter((item) => item.facility === facility);
    } else if (filter?.facility && !facility) {
      delete filter.facility;
    }

    setNewTotals(filteredData);
    setFilters(filter);
    setFilter(true);
  };

  //Reset filters when organisation changes
  useEffect(() => {
    setUsageCategory(null);
    setFacility(null);
    setReportingYear(null);
    setFilter(false);
    setNewTotals([]);
  }, [currentOrganisation]);

  //Update filtered data when new usage data is added
  useEffect(() => {
    if (reportingYear !== undefined) {
      getUsageTotals(reportingYear);
    }
    //eslint-disable-next-line
  }, [reportingYear]);

  //Update filtered data when filters change
  useEffect(() => {
    if (!usageCategory && !facility) {
      setFilter(false);
      setFilters({});
    } else {
      updateFilteredData();
    }
    //eslint-disable-next-line
  }, [usageCategory, facility, reportingYear, usageTotals]);

  useEffect(() => {
    const getStartDate = (endDate) => {
      return moment(endDate).add(-1, 'year').add(1, 'day').format('DD/MM/YYYY');
    };

    if (orgDetails?.usageYears?.minStartDate) {
      let factorYear = 0;
      if (orgDetails.reportingYearEndMonth > 6) {
        factorYear = new Date(orgDetails?.usageYears?.minStartDate).getFullYear();
      } else {
        factorYear = new Date(orgDetails?.usageYears?.minStartDate).getFullYear() - 1;
      }
      const reportingYearEndDates = [
        {
          endDate: new Date(factorYear + '-' + orgDetails.reportingYearEndMonth + '-' + orgDetails.reportingYearEndDay),
          value: factorYear,
        },
      ];
      while (
        orgDetails.reportingYearEndMonth == 12 && orgDetails.reportingYearEndDay == 31
          ? factorYear < new Date(orgDetails?.usageYears?.maxEndDate).getFullYear()
          : factorYear <= new Date(orgDetails?.usageYears?.maxEndDate).getFullYear()
      ) {
        factorYear++;
        let date = new Date(factorYear + '-' + orgDetails.reportingYearEndMonth + '-' + orgDetails.reportingYearEndDay);
        reportingYearEndDates.push({ endDate: date, value: factorYear });
      }

      let reportingDates = _.orderBy(
        _.uniqBy(reportingYearEndDates, 'endDate').map((item) => {
          return {
            value: item.value,
            label: getStartDate(item.endDate) + ' - ' + moment(item.endDate).format('DD/MM/YYYY'),
          };
        }),
        'value',
        'asc'
      );

      const allOption = {
        value: 'all',
        label: 'All',
      };

      reportingDates.unshift(allOption); //Insert all option at the beginning of the array
      setReportingYearOptions(reportingDates);
    } else {
      setReportingYear(null);
      setReportingYearOptions([]);
    }
  }, [orgDetails?.id, orgDetails?.usageYears?.minStartDate, orgDetails?.usageYears?.maxEndDate]);

  const groupedTotals = _.groupBy(usageTotals, (item) => item.entityName); //group usageTotals by entityName

  let newGroupedTotals = _.groupBy(newTotals, (item) => item.entityName);

  const entities = Object.keys(groupedTotals).sort();

  const newEntities = Object.keys(newGroupedTotals).sort();

  if (!usageTotals?.length > 0 && !usageTotals) {
    return <LinearProgress sx={{ marginTop: '3vh' }} color='inherit' />; //Loader
  }

  const usageOptions = _.uniqBy(usageTotals, 'entity').map((item) => {
    return {
      value: item.entity,
      label: item.entityName,
    };
  });

  return (
    <div className='row' style={{ marginTop: '40px', fontFamily: headers }}>
      <div className='col-lg-12'>
        <section className='panel'>
          <header className='panel-heading text-center'>
            <h5 style={{ position: 'relative' }}>
              Filter By:
              <AddButton
                disabled={disabled}
                style={{ position: 'absolute', right: 0, bottom: -8 }}
                onClick={async () => {
                  filters.exportClientUsage = true;
                  filters.organisation = currentOrganisation;
                  setDisabled(true);
                  await getItemsByTermsFromIndexExport('usageData', filters);
                  setDisabled(false);
                }}>
                <i className='fa-solid fa-cloud-arrow-down' style={{ marginRight: 5 }} />
                Export Usage Data
              </AddButton>
            </h5>
            <hr></hr>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: 'inherit' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40%' }}>
                <span style={{ padding: '10px' }}>
                  <b>Data Starts Between: </b>
                </span>
                <div style={{ width: '52%' }}>
                  <Select
                    id={'UsageDataFilters-reportingYear'}
                    name={'reportingYear'}
                    options={reportingYearOptions?.sort(function (a, b) {
                      return new Date(a.value) - new Date(b.value);
                    })}
                    style={adminPortalSelect}
                    components={{ Option: CustomOption('UsageDataFilters') }}
                    onChange={(selected) => {
                      setReportingYear(selected?.value);
                    }}
                    value={
                      reportingYearOptions?.find((obj) => obj.value == reportingYear) ||
                      reportingYearOptions?.find((obj) => obj.value == usageDataYear) || null
                    }
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30%' }}>
                <span style={{ padding: '10px' }}>
                  <b>Usage Category: </b>
                </span>
                <div style={{ width: '60%' }}>
                  <Select
                    id={'UsageDataFilters-usageCategory'}
                    name={'usageCategory'}
                    options={usageOptions}
                    style={adminPortalSelect}
                    components={{ Option: CustomOption('UsageDataFilters') }}
                    onChange={(selected) => {
                      setUsageCategory(selected?.value);
                    }}
                    isClearable={true}
                    value={usageOptions.find((obj) => obj.value === usageCategory) || ''}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30%' }}>
                <span style={{ padding: '10px' }}>
                  <b>Facility: </b>
                </span>
                <div style={{ width: '60%' }}>
                  <Select
                    id={'UsageDataFilters-facility'}
                    name={'facility'}
                    options={facilityOptions}
                    style={adminPortalSelect}
                    components={{ Option: CustomOption('UsageDataFilters') }}
                    onChange={(selected) => {
                      setFacility(selected?.value);
                    }}
                    isClearable={true}
                    value={facilityOptions?.find((obj) => obj.value === facility) || ''}
                  />
                </div>
              </div>
            </div>
          </header>

          {loading && (
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', paddingBottom: '50px' }}>
              <Waveform size={40} lineWeight={3.5} speed={1} color='black' />
            </div>
          )}

          {!loading && (
            <div className='panel-body'>
              <table className='table table-hover general-table usage-table'>
                {filter === false
                  ? entities?.map((entity, index) => (
                      <EntityData key={index} usageTotals={groupedTotals[entity]} index={entity} entity={entity} />
                    ))
                  : newEntities?.map((entity, index) => (
                      <EntityData key={index} usageTotals={newGroupedTotals[entity]} index={entity} entity={entity} />
                    ))}
              </table>
              {newTotals?.length === 0 && filter === true && (
                <p style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold', fontSize: '16px', margin: 10 }}>{`There are no search results for ${entityTable?.find((e) => e.id == usageCategory)?.name || 'your filters'}!`}</p>
              )}
              {usageTotals && !usageTotals.length > 0 && filter === false && (
                <div style={{ display: 'flex', justifyContent: 'center' }} className='alert-message'>
                  <i className='fa-solid fa-triangle-exclamation' style={{ marginTop: '6px' }} />
                  <span>No Usage Data Found</span>
                </div>
              )}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { cyfAdmin } = state.profile?.details || {};
  return {
    orgDetails: state.organisation.details,
    usageTotals: state.usageTotals,
    currentOrganisation: state.currentOrganisation,
    branding: state.branding,
    cyfAdmin,
    facilityOptions: state.facility.list?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    }),
    loading: state.usageProcessing,
    usageDataYear: state.usageDataYear,
    entityTable: state?.entities,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getItemsByTermsFromIndexExport: (index, filters, page, size) =>
    dispatch(ADMINACTIONS.getItemsByTermsFromIndexExport(index, filters, page, size)),
  getUsageTotals: (year) => dispatch(USAGEACTIONS.getUsageTotals(year)),
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(UsageTable));

import _ from 'lodash';
import { Types } from '../actions/reportActions';

const defaultState = {
  my: {},
};

const reportReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.ADD_MODAL: {
      let newState = _.cloneDeep(state);

      newState.report.modal = action.status;

      // When user needs to update subscription to generate a new report
      if (action.updateSubscription) {
        newState.report.updateSubscription = true;
      }

      return newState;
    }
    case Types.ADD_REPORT: {
      let newState = _.cloneDeep(state);

      newState.report.addingReport = false;
      newState.report.modal = false;
      newState.report.list = action.payload.reports;

      return newState;
    }
    case Types.ADDING_REPORT: {
      let newState = _.cloneDeep(state);

      newState.report.addingReport = true;

      return newState;
    }
    case Types.LIST: {
      let newState = _.cloneDeep(state);

      newState.report = state.report ? { ...state.report } : {};
      newState.report.list = action.payload;
      newState.report.details = null;

      return newState;
    }
    case Types.DOWNLOAD_STATUS: {
      let newState = _.cloneDeep(state);

      newState.report = state.report ? { ...state.report } : {};

      if (!newState.report.download) {
        newState.report.download = {};
      }

      newState.report.download[action.filetype] = action.status;

      return newState;
    }
    case Types.DETAILS: {
      let newState = _.cloneDeep(state);
      const { organisationId } = action.payload;

      newState.report = state.report ? { ...state.report } : {};
      newState.report.details = action.payload;
      newState.report.includedOrgs = [];

      newState.currentOrganisation = organisationId;

      return newState;
    }

    case Types.INCLUDED_ORGS: {
      let newState = _.cloneDeep(state);

      newState.report.includedOrgs = action?.orgs;

      return newState;
    }

    case Types.SHOW_CONSUMPTION_ALERT: {
      let newState = _.cloneDeep(state);

      newState.report.showConsumptionAlert = action?.show;

      return newState;
    }

    default:
      return state;
  }
};

export default reportReducers;

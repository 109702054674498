import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

const formatNumber = (value) => parseFloat(value || 0).toLocaleString(navigator.language, { minimumFractionDigits: 3 });

const EmissionsDisaggregatedByOrganisationRow = ({ facility, orgID, facilityData, organisations, adminOrgs }) => {
  return (
    <tr>
      <td width='20%' style={{ textAlign: 'left' }}>
        {orgID === 'all' ? 'Total' : adminOrgs && adminOrgs?.find((item) => item.id == orgID)?.name}
      </td>
      <td>
        <span className='toggle-location'>
          {formatNumber(organisations[orgID] && organisations[orgID].co2e ? organisations[orgID].co2e.toFixed(3) : '0.000')}
        </span>
        <span className='toggle-market'>
          {formatNumber(
            organisations[orgID] && organisations[orgID].marketScope
              ? _.sum(Object.values(organisations[orgID].marketScope))
              : 0
          )}
        </span>
      </td>
      <td>
        <span className='toggle-location'>
          {formatNumber(
            organisations[orgID] && organisations[orgID].scope[1] ? organisations[orgID].scope[1].toFixed(3) : '0.000'
          )}
        </span>
        <span className='toggle-market'>
          {formatNumber(
            organisations[orgID] && organisations[orgID].marketScope[1]
              ? organisations[orgID].marketScope[1].toFixed(3)
              : organisations[orgID] && organisations[orgID].scope[1]
              ? organisations[orgID].scope[1].toFixed(3)
              : '0.000'
          )}
        </span>
      </td>
      <td>
        <span className='toggle-location'>
          {formatNumber(
            organisations[orgID] && organisations[orgID].scope[2] ? organisations[orgID].scope[2].toFixed(3) : '0.000'
          )}
        </span>
        <span className='toggle-market'>
          {formatNumber(
            organisations[orgID] && organisations[orgID].marketScope[2]
              ? organisations[orgID].marketScope[2].toFixed(3)
              : '0.000'
          )}
        </span>
      </td>
      <td>
        <span className='toggle-location'>
          {formatNumber(
            organisations[orgID] && organisations[orgID].scope[3] ? organisations[orgID].scope[3].toFixed(3) : '0.000'
          )}
        </span>
        <span className='toggle-market'>
          {formatNumber(
            organisations[orgID] && organisations[orgID].marketScope[3]
              ? organisations[orgID].marketScope[3].toFixed(3)
              : '0.000'
          )}
        </span>
      </td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { emissionsByFacility } = state.consumption;
  const adminOrgs = state.admin?.organisations;
  const organisationList = state.organisation?.list;
  const { cyfAdmin, whiteLabelAdmin, greenElementUser } = state.profile?.details || {};
  const adminUser = cyfAdmin || whiteLabelAdmin || greenElementUser || false;

  const { facilityID } = ownProps;

  const facilityData =
    state.report &&
    state.report.details &&
    state.report.details.year &&
    emissionsByFacility[state.report.details.year] &&
    emissionsByFacility[state.report.details.year][facilityID];
  const facility =
    emissionsByFacility &&
    state?.facility?.hierarchyFacilities &&
    _.uniqBy(state.facility?.hierarchyFacilities, 'id')?.find((item) => item.id == facilityID);

  return {
    facilityData,
    facility,
    adminOrgs: adminUser ? adminOrgs : organisationList,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsDisaggregatedByOrganisationRow);

import React from 'react';
import { Alert } from '@mui/material';
import Select, { components } from 'react-select';

const NoPermissions = () => {
  return (
    <Alert severity='error' style={{ fontSize: '14px', padding: '20px', fontWeight: 'bold', border: '1px solid grey' }}>
      You do not have permissions to access this page.
    </Alert>
  );
};

const BetaTag = ({ color, backgroundColor }) => {
  return (
    <span
      className='beta-tag'
      style={{
        color: color,
        backgroundColor: backgroundColor,
      }}>
      BETA
    </span>
  );
};

const CustomOption = (pageName) => (props) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div id={`${pageName}-option-${data.label}`}>{data.label}</div>
    </components.Option>
  );
};

export { NoPermissions, BetaTag, CustomOption };

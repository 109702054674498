import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import Select, { components } from 'react-select';
import { RemoveTags } from '../utils/GlobalFunctions';
import { CustomOption } from '../Common/ReusableComponents/Reusable';
import { connect } from 'react-redux';

const Control = ({ children, ...props }) => {
  const { value, primaryColor, tooltipBackground, tooltipText } = props.selectProps;
  const style = { cursor: 'pointer', display: value?.description ? 'block' : 'none' };

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  return (
    <components.Control {...props}>
      <span style={style} ref={setTriggerRef}>
        <i className='fa fa-question-circle' style={{ color: primaryColor }} />
      </span>
      {value && value.description !== null && value.description !== undefined && visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container',
            style: {
              marginLeft: 10,
              marginTop: 10,
              backgroundColor: tooltipBackground,
              color: tooltipText,
              borderRadius: 5,
            },
          })}>
          <div
            {...getArrowProps({
              className: 'tooltip-arrow',
              style: { color: tooltipBackground, left: '-15px', top: '-3px' },
            })}
          />
          <div className='tooltip-body' style={{ color: 'white', textAlign: 'justify' }}>
            <ReactMarkdown>{RemoveTags(value.description)}</ReactMarkdown>
          </div>
        </div>
      )}
      {children}
    </components.Control>
  );
};

const CustomSelectProps = ({
  item,
  name,
  value,
  zIndex,
  options,
  branding,
  onChange,
  components,
  maxMenuHeight,
  editUsageModal,
  ...props
}) => {
  const styles = {
    control: (css) => ({ ...css, paddingLeft: '1rem' }),
    container: (x) => ({ ...x, zIndex: zIndex ? zIndex : 10 }),
  };

  const { primaryColor, tooltipBackground, tooltipText } = branding || {};

  useEffect(() => {
    if (!item?.[name] && options.length === 1 && name !== 'subentity' && editUsageModal) {
      editUsageModal(options[0].value, { name: name });
    }
  }, [item, name, options, editUsageModal]);

  return (
    <Select
      {...props}
      maxMenuHeight={maxMenuHeight || 250}
      components={{ Control, Option: CustomOption('UsageData'), ...components }}
      isSearchable
      name={name}
      id={`UsageData-${name}`}
      options={options}
      styles={styles}
      value={value}
      onChange={onChange}
      primaryColor={primaryColor}
      tooltipBackground={tooltipBackground}
      tooltipText={tooltipText}
    />
  );
};

const mapStateToProps = (state) => ({
  branding: state?.branding?.colors,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CustomSelectProps);

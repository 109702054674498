import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
import { default as React, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as yup from 'yup';
import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { itemToOptions } from '../utils/GlobalFunctions';
import { whiteLabelSelect } from '../utils/ReactSelectStyles';
import ValidationMessage from '../utils/ValidationMessage';

const validateUserSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email().required('Email is required'),
});

const InviteUserForm = ({
  inviteNewUser,
  cyfAdmin,
  setShowInviteUserForm,
  showInviteUserForm,
  whiteLabelAdminOrgs,
  consultantAdminOrgs,
  branding,
}) => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: 0,
    role: '',
    whiteLabelAdmin: null,
    consultantAdmin: null,
  });

  const [showWLAdminField, setShowWLAdminField] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [validationError, setValidationError] = useState([]);
  const { primaryColor, tooltipBackground, tooltipText } = branding || {};

  const updateField = (e) => {
    const editedUser = { ...user };
    editedUser[e.target.name] = e.target.value;
    setUser(editedUser);
  };

  const handleInviteUser = async () => {
    try {
      setProcessing(true);
      await validateUserSchema.validate(user, { abortEarly: false });
      await inviteNewUser(user);
      setShowInviteUserForm(!showInviteUserForm);
    } catch (error) {
      console.log('Error inviting user', error);
      if (error?.inner) {
        setValidationError(error.inner);
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className='modal' style={{ display: 'flex', alignItems: 'baseline', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-content' style={{ margin: '70px auto', width: '400px', height: 'auto' }}>
        <div className='modal-header'>
          <button id='close-button' onClick={() => setShowInviteUserForm(!showInviteUserForm)} className='close'>
            x
          </button>
          <h4 className='modal-title'>Invite New User</h4>
        </div>
        <div className='modal-body' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className='form-group'>
            <input
              id='firstName'
              type='text'
              className='form-control'
              name='firstName'
              onChange={updateField}
              placeholder='First Name *'
              style={{ width: '350px', marginTop: 20, height: '5vh' }}
            />
            <ValidationMessage
              message={_.find(validationError, { path: 'firstName' })?.message}
              style={{ marginBottom: 0 }}
            />
          </div>

          <div className='form-group'>
            <input
              id='surname'
              type='text'
              className='form-control'
              name='lastName'
              onChange={updateField}
              placeholder='Surname *'
              style={{ width: '350px', marginTop: 15, height: '5vh' }}
            />
            <ValidationMessage
              message={_.find(validationError, { path: 'lastName' })?.message}
              style={{ marginBottom: 0 }}
            />
          </div>

          <div className='form-group'>
            <input
              id='role'
              type='text'
              className='form-control'
              name='role'
              onChange={updateField}
              placeholder='Job Title'
              style={{ width: '350px', marginTop: 15, height: '5vh' }}
            />
          </div>

          <div className='input-with-icon-block'>
            <input
              id='email'
              type='email'
              className='form-control'
              name='email'
              onChange={updateField}
              placeholder='Email (case sensitive)*'
              style={{ width: '350px', marginTop: 15, height: '5vh' }}
            />
            <Tooltip
              title={
                <span style={{ fontSize: 12 }}>
                  Email addresses are case sensitive throughout the application. Please enter your email exactly as you wish
                  to use it going forward. You will receive a confirmation of registration when this process is completed,
                  please retain this email for reference to your registered email ID.
                </span>
              }
              arrow
              placement='left'
              componentsProps={{
                tooltip: { sx: { backgroundColor: tooltipBackground, color: tooltipText } },
                arrow: { sx: { color: tooltipBackground } },
              }}>
              <i
                className='fa fa-info-circle'
                style={{
                  transform: 'none',
                  top: _.find(validationError, { path: 'email' })?.message ? '40%' : '50%',
                  color: primaryColor,
                }}
              />
            </Tooltip>
            <ValidationMessage message={_.find(validationError, { path: 'email' })?.message} style={{ marginBottom: 0 }} />
          </div>

          <div className='form-group' style={{ marginTop: 30 }}>
            <PhoneInput
              country={'gb'}
              inputProps={{ name: 'phoneNumber' }}
              placeholder='Phone Number *'
              inputClass='inputClassInviteUser'
              onChange={(phoneNumber) => {
                const editedUser = { ...user };
                editedUser['phoneNumber'] = phoneNumber;
                setUser(editedUser);
              }}
            />
            {cyfAdmin && (
              <p
                id='show-white-label'
                style={{ fontSize: 12, fontWeight: 'bold', display: 'inline-block', marginTop: 20, cursor: 'pointer' }}
                onClick={() => setShowWLAdminField(!showWLAdminField)}>
                {!showWLAdminField ? (
                  <span>
                    <i className='fa-solid fa-triangle-exclamation' /> Consultant or White Label Admin? Click here
                  </span>
                ) : (
                  <span>Close</span>
                )}
              </p>
            )}
          </div>

          <div className='form-group' style={{ display: cyfAdmin && showWLAdminField ? 'block' : 'none', marginBottom: 30 }}>
            <Select
              id='white-label-admin-dropdown'
              options={whiteLabelAdminOrgs}
              value={whiteLabelAdminOrgs.find((org) => org.value === user['whiteLabelAdmin']) || null}
              styles={whiteLabelSelect}
              isClearable={true}
              placeholder='White Label Admin'
              onChange={(selected) => {
                const editedUser = { ...user };
                if (!selected) {
                  editedUser['whiteLabelAdmin'] = null;
                } else {
                  editedUser['whiteLabelAdmin'] = selected.value;
                }
                setUser({ ...editedUser, consultantAdmin: null });
              }}
            />
            <Select
              id='white-label-admin-dropdown'
              options={consultantAdminOrgs}
              value={consultantAdminOrgs.find((org) => org.value === user['consultantAdmin']) || null}
              isClearable={true}
              styles={{ ...whiteLabelSelect, control: (provided) => ({ ...provided, marginTop: 20 }) }}
              placeholder='Consultant Admin'
              onChange={(selected) => {
                const editedUser = { ...user };
                if (!selected) {
                  editedUser['consultantAdmin'] = null;
                } else {
                  editedUser['whiteLabelAdmin'] = null;
                  editedUser['consultantAdmin'] = selected.value;
                }
                setUser(editedUser);
              }}
            />
          </div>

          <div className='form-group' style={{ marginBottom: 20 }}>
            <Alert severity='info'>
              <b>
                Please kindly note that new user invitations expire 1 week after being sent. Invitee must login and reset
                their password within that window.
              </b>
            </Alert>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div>
              <AddButton id='submit-button' disabled={processing ? true : false} onClick={handleInviteUser}>
                {processing && <i className='fa fa-spin fa-spinner icon-spinner' />}
                Submit
              </AddButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const allOrgs = state.admin?.organisations;
  const cyfAdmin = state.profile?.details?.cyfAdmin;

  let whiteLabelAdminOrgs, consultantAdminOrgs;

  if (cyfAdmin) {
    whiteLabelAdminOrgs = allOrgs && allOrgs.filter((org) => org.id === org.whiteLabelId).map(itemToOptions);
    whiteLabelAdminOrgs && whiteLabelAdminOrgs.push({ label: 'Compare Your Footprint', value: '621784' });
    consultantAdminOrgs = allOrgs && allOrgs.filter((org) => org.id === org.consultantId).map(itemToOptions);
  }

  return {
    branding: state.branding?.colors,
    cyfAdmin,
    whiteLabelAdminOrgs: _.sortBy(whiteLabelAdminOrgs, ['label']),
    consultantAdminOrgs: _.sortBy(consultantAdminOrgs, ['label']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  inviteNewUser: (user) => dispatch(AUTHENTICATION_ACTIONS.inviteNewUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserForm);

import React from 'react';
import { connect } from 'react-redux';
import { PeriodMaker } from '../utils/GlobalFunctions';

import EmissionsByMainGreenhouseGasesRow from './EmissionsByMainGreenhouseGasesRow';

const EmissionsByMainGreenhouseGases = ({
  report,
  totalEmissionsByScope,
  currentOrganisation,
  adminOrgs,
  includedOrganisations,
  orgsInReport,
}) => {
  const year = report.year;
  let isReportEmpty = Object.keys(report).length === 0;

  const hasComparative = totalEmissionsByScope && totalEmissionsByScope[year - 1];

  let lastPeriod;
  let currentPeriod;

  if (!isReportEmpty) {
    let referenceYear = +report.endDate.split('-')[0];
    lastPeriod = hasComparative ? PeriodMaker(report.startDate, report.endDate, referenceYear - 1) : null;
    currentPeriod = PeriodMaker(report.startDate, report.endDate, referenceYear);
  }
  return (
    <div>
      <br />
      <br />
      <h3>Total emissions disaggregated by main greenhouse gases</h3>
      {includedOrganisations?.length === 0 && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 600,
              fontSize: '15px',
              borderBottom: '1px solid #ddd',
            }}>
            {'Organisation: ' + adminOrgs?.find((org) => org.id === currentOrganisation)?.name}
          </div>
          <table id='emissions-by-main-ghg-table' className='table table-hover general-table' style={{ textAlign: 'right' }}>
            <thead>
              <tr>
                <th></th>
                {hasComparative && (
                  <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    Total (tCO2e) <br />({lastPeriod})
                  </th>
                )}
                <th style={{ textAlign: 'right' }}>
                  Total (tCO2e) <br />({currentPeriod})
                </th>
                {hasComparative && (
                  <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    tCO2 <br />({lastPeriod})
                  </th>
                )}
                <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                  tCO2 <br />({currentPeriod})
                </th>
                {hasComparative && (
                  <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    tCH4 <br />({lastPeriod})
                  </th>
                )}
                <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                  tCH4 <br />({currentPeriod})
                </th>
                {hasComparative && (
                  <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    tN2O <br />({lastPeriod})
                  </th>
                )}
                <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                  tN2O <br />({currentPeriod})
                </th>
              </tr>
            </thead>
            <tbody>
              <EmissionsByMainGreenhouseGasesRow org={currentOrganisation} scope='1' />
              <EmissionsByMainGreenhouseGasesRow org={currentOrganisation} scope='2' />
              <EmissionsByMainGreenhouseGasesRow org={currentOrganisation} scope='3' />
              <EmissionsByMainGreenhouseGasesRow org={currentOrganisation} />
            </tbody>
          </table>
        </>
      )}

      {/* {totalEmissionsByScope?.[year] &&
        Object?.keys(totalEmissionsByScope[year])?.length > 1 &&
        Object?.keys(totalEmissionsByScope[year])
          ?.filter((org) => org !== currentOrganisation)
          ?.map((org, index) => {
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 600,
                    fontSize: '15px',
                    borderBottom: '1px solid #ddd',
                  }}>
                  {'Organisation: ' + adminOrgs?.find((organisation) => organisation.id === org)?.name}
                </div>
                <table
                  id={`emissions-by-main-ghg-table-${org}`}
                  className='table table-hover general-table'
                  style={{ textAlign: 'right' }}
                  key={`${org}-${index}`}>
                  <thead>
                    <tr>
                      <th></th>
                      <th style={{ textAlign: 'right' }}>
                        Total (tCO2e) <br />({currentPeriod})
                      </th>
                      {hasComparative && (
                        <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                          Total (tCO2e) <br />({lastPeriod})
                        </th>
                      )}
                      <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                        tCO2 <br />({currentPeriod})
                      </th>
                      {hasComparative && (
                        <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                          tCO2 <br />({lastPeriod})
                        </th>
                      )}
                      <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                        tCH4 <br />({currentPeriod})
                      </th>
                      {hasComparative && (
                        <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                          tCH4 <br />({lastPeriod})
                        </th>
                      )}
                      <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                        tN2O <br />({currentPeriod})
                      </th>
                      {hasComparative && (
                        <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                          tN2O <br />({lastPeriod})
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <EmissionsByMainGreenhouseGasesRow org={org} scope='1' />
                    <EmissionsByMainGreenhouseGasesRow org={org} scope='2' />
                    <EmissionsByMainGreenhouseGasesRow org={org} scope='3' />
                    <EmissionsByMainGreenhouseGasesRow org={org} />
                  </tbody>
                </table>
              </>
            );
          })} */}

      {totalEmissionsByScope?.[year] &&
        includedOrganisations?.length > 0 /* Object?.keys(totalEmissionsByScope[year])?.length > 1 */ && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 600,
                fontSize: '15px',
                borderBottom: '1px solid #ddd',
              }}>
              {'Organisations: ' + orgsInReport}
            </div>
            <table
              id={`emissions-by-main-ghg-table-group`}
              className='table table-hover general-table'
              style={{ textAlign: 'right' }}>
              <thead>
                <tr>
                  <th></th>
                  {hasComparative && (
                    <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                      Total (tCO2e) <br />({lastPeriod})
                    </th>
                  )}
                  <th style={{ textAlign: 'right' }}>
                    Total (tCO2e) <br />({currentPeriod})
                  </th>
                  {hasComparative && (
                    <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                      tCO2 <br />({lastPeriod})
                    </th>
                  )}
                  <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    tCO2 <br />({currentPeriod})
                  </th>
                  {hasComparative && (
                    <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                      tCH4 <br />({lastPeriod})
                    </th>
                  )}
                  <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    tCH4 <br />({currentPeriod})
                  </th>
                  {hasComparative && (
                    <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                      tN2O <br />({lastPeriod})
                    </th>
                  )}
                  <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                    tN2O <br />({currentPeriod})
                  </th>
                </tr>
              </thead>
              <tbody>
                <EmissionsByMainGreenhouseGasesRow org={'all'} scope='1' />
                <EmissionsByMainGreenhouseGasesRow org={'all'} scope='2' />
                <EmissionsByMainGreenhouseGasesRow org={'all'} scope='3' />
                <EmissionsByMainGreenhouseGasesRow org={'all'} />
              </tbody>
            </table>
          </>
        )}

      <p>
        tCO2: tonnes of carbon dioxide
        <br />
        tCH4: tonnes of methane
        <br />
        tN2O: tonnes of nitrous oxide
      </p>
      <p style={{ width: '50%', textAlign: 'justify' }}>
        N.B. The total carbon dioxide equivalent GHGs (tCO2e) will not equal the sum of the breakdown into the 3 main gases
        in this table due to the fact that not all emissions intensities are provided split out by individual gas, plus there
        are four other groups of Kyoto Protocol gases not included in the table.
      </p>
      <br></br>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { totalEmissionsByScope, years, filter } = state.consumption || {};

  const { organisation, report } = state;

  const adminOrgs = state.admin?.organisations;
  const organisationList = state.organisation?.list;
  const { cyfAdmin, whiteLabelAdmin, greenElementUser } = state.profile?.details || {};
  const adminUser = cyfAdmin || whiteLabelAdmin || greenElementUser || false;
  const organisations = adminUser ? adminOrgs : organisationList;
  const currentOrganisation = state.currentOrganisation;
  const includedOrganisations = state.report?.includedOrgs;
  const currentOrgName = organisations?.find((org) => org.id === currentOrganisation)?.name;
  const orgsInReport =
    includedOrganisations?.length > 2
      ? currentOrgName + ' and ' + includedOrganisations?.length + ' others'
      : currentOrgName +
        ', ' +
        includedOrganisations?.map((organisation) => organisations?.find((org) => org.id === organisation)?.name).join(', ');

  return {
    report: report?.details || {},
    totalEmissionsByScope: totalEmissionsByScope,
    consumptionFilter: filter || [],
    years: years || [],
    metrics: state?.metric?.list,
    organisationDetails: organisation?.details,
    organisationMetrics: organisation?.metrics,
    metricValues: organisation.metrics ? organisation.metrics : null,
    currentOrganisation,
    adminOrgs: adminUser ? adminOrgs : organisationList,
    includedOrganisations,
    orgsInReport,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsByMainGreenhouseGases);
